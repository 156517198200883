.agreement-acceptance-page{
    overflow: hidden;
    position: relative;
}

.agreement-acceptance-page {
    /* padding: 20px; */
    box-sizing: border-box;
    background-color: #FFF;
    text-align: center;
}

.content {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    flex-direction: column;
}

.icon-header {
    display: flex;
    flex-direction: column; /* Aligns the content in a column */
    align-items: flex-start; /* Aligns items to the start of the column */
    margin-bottom: 10px;
    text-align: left; /* Aligns text to the left */
}
.header-container {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    border-bottom: 1px solid #ccc;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
}

.pdf-icon {
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../../public/assets/images/pdf.svg');
    background-size: 60px 60px;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 10px;
}

.header-container p {
    margin: 0;
    font-size: 18px;
    color: #4D4C47;
    font-weight: bold;
}

.icon-circle {
    width: 130px;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../../public/assets/images/tick-icon.svg');
    background-size: 60px 60px;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 10px;
}

h1 {
    margin: 0;
    font-size: 36px;
    color: #4D4C47;
    font-weight: bold;
}

h3 {
    margin: 0;
    color: #57504F;
    font-size: 18px;
    font-weight: 500;
}

/* Responsive adjustments */
@media (max-width: 480px) {
    .icon-circle {
        width: 66px;
        height: 56px;
        background-size: 60px 60px;
        margin-right: 0px;
    }
    .pdf-icon {
        width: 50px;
        height: 50px;        
    }
    .content{
        justify-content:center;
        text-align: left;    
        align-items: flex-start;
        padding: 30px;
    }
    .header-container {
        padding:10px
    }
    .header-container p{
        font-size: 14px;
    }
    h1 {
        font-size: 36px;
    }

    h3 {
        font-size: 18px;
    }
}