@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

section ul,
section ol {
  margin: 20px 20px 20px 25px;
  font-size: 16px;
}

section ul li,
section ol li {
  margin: 5px 0;
  list-style-position: outside !Important;
}

/*font-family: 'Montserrat', sans-serif;*/
body {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  color: #414042;
  line-height: 1.42857143;
}

#root {
  position: relative;
  overflow: hidden;
}

p {
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  color: #45474d;
  font-weight: 400;
}

.section-title {
  font-size: 48px;
  line-height: 68px;
}

h1 {
  font-size: 48px;
  line-height: 68px;
  font-weight: 500;
}

img {
  display: block;
  max-width: 100%;
  outline: none;
}

header,
footer,
section,
article {
  display: block;
}

.section-heading {
  color: #414042;
  font-size: 30px;
  line-height: 40px;
  font-weight: 500;
  margin: 0 0 10px 0;
}

.section-sub-heading {
  color: #414042;
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  margin-top: 15px;
}

.section-sub-title {
  font-size: 16px;
  font-weight: 600;
}

.d-none {
  display: none;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flexbox;
}

.direction-columan {
  flex-direction: column;
}

.row-reverse {
  flex-direction: row-reverse;
}

.align-center {
  align-items: center;
}

.align-flex-start {
  align-items: flex-start;
}

.align-flex-end {
  align-items: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-space-between {
  justify-content: space-between;
}

.flex-wrap {
  flex-wrap: wrap;
}

.ml-auto {
  margin-left: auto;
}

.mlr-auto {
  margin-left: auto;
  margin-right: auto;
}

.uppercase {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.animatedParent {
  overflow: hidden;
}

.no-border {
  border: 0 !important;
}

.submit-button {
  background: none;
  border: none;
}

.container {
  max-width: 1230px;
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 auto;
}

.container-fluid {
  max-width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.common-btn {
  border-radius: 30px;
  background-image: linear-gradient(to right, #56bec0 0%, #3995cf 0%, #3a83c1 27%, #395ba4 84%, #394f9c 100%);
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  padding: 15px 20px;
  text-align: center;
  display: inline-block;
  text-decoration: none;
  border: 0;
  cursor: pointer;
}

button.common-btn:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.common-btn:hover {
  background-image: linear-gradient(to right, #394f9c 0%, #395ba4 0%, #3a83c1 27%, #3995cf 84%, #56bec0 100%);
}

.common-btn.btn-l {
  padding: 15px 70px;
}

.common-btn.common-secondry-btn {
  border: solid 1px #38519e;
  background: #fff;
  color: #38519e;
}

.common-btn.common-secondry-btn:hover {
  background-image: linear-gradient(to right, #394f9c 0%, #395ba4 0%, #3a83c1 27%, #3995cf 84%, #56bec0 100%);
  color: #fff;
}

.no-pad {
  padding: 0;
}

.no-margin {
  margin: 0;
}

.p-t-0 {
  padding-top: 0;
}

.p-t-5 {
  padding-top: 5px;
}

.p-t-10 {
  padding-top: 10px;
}

.p-t-15 {
  padding-top: 15px;
}

.p-t-20 {
  padding-top: 20px;
}

.p-t-25 {
  padding-top: 25px;
}

.p-t-30 {
  padding-top: 30px;
}

.p-t-35 {
  padding-top: 35px;
}

.p-t-40 {
  padding-top: 40px;
}

.p-t-45 {
  padding-top: 45px;
}

.p-t-50 {
  padding-top: 50px;
}

.p-b-0 {
  padding-bottom: 0px;
}

.p-b-5 {
  padding-bottom: 5px;
}

.p-b-10 {
  padding-bottom: 10px;
}

.p-b-15 {
  padding-bottom: 15px;
}

.p-b-20 {
  padding-bottom: 20px;
}

.p-b-25 {
  padding-bottom: 25px;
}

.p-b-30 {
  padding-bottom: 30px;
}

.p-b-35 {
  padding-bottom: 35px;
}

.p-b-40 {
  padding-bottom: 40px;
}

.p-b-45 {
  padding-bottom: 45px;
}

.p-b-50 {
  padding-bottom: 50px;
}

.m-t-0 {
  margin-top: 0;
}

.m-t-5 {
  margin-top: 5px;
}

.m-t-10 {
  margin-top: 10px;
}

.m-t-15 {
  margin-top: 15px;
}

.m-t-20 {
  margin-top: 20px;
}

.m-t-25 {
  margin-top: 25px;
}

.m-t-30 {
  margin-top: 30px;
}

.m-t-35 {
  margin-top: 35px;
}

.m-t-40 {
  margin-top: 40px;
}

.m-t-45 {
  margin-top: 45px;
}

.m-t-45 {
  margin-top: 45px;
}

.m-t-50 {
  margin-top: 50px;
}

.m-b-0 {
  margin-bottom: 0px;
}

.m-b-5 {
  margin-bottom: 5px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-b-15 {
  margin-bottom: 15px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-b-25 {
  margin-bottom: 25px;
}

.m-b-30 {
  margin-bottom: 30px;
}

.m-b-35 {
  margin-bottom: 35px;
}

.m-b-40 {
  margin-bottom: 40px;
}

.m-b-45 {
  margin-bottom: 45px;
}

.m-b-50 {
  margin-bottom: 50px;
}

.error-c {
  color: #ff0e00;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.gap-30 {
  gap: 30px;
}

.cursor-pointer {
  cursor: pointer;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table {
  caption-side: bottom;
  border-collapse: collapse;
  vertical-align: top;
  border-color: #dee2e6;
  width: 100%;
}

.table>thead {
  vertical-align: bottom;
}

.table td {
  padding: 5px;
}

.horizontal-separator {
  border-bottom: solid 1px #bbbbbb;
  width: 100%;
}

.status-message-box {
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  margin: 0 auto;
  padding: 10px 40px 10px 50px;
  border-left-style: solid;
  border-left-width: 4px;
  position: fixed;
  top: 30px;
  z-index: 99;
  max-width: 500px;
  width: 100%;
  left: 0;
  right: 0;
}

.status-message-box strong {
  display: block;
  font-size: 18px;
}

.status-message-box p {
  display: block;
  font-size: 14px;
}

.close-message {
  position: absolute;
  right: 15px;
  top: 25px;
  font-size: 12px;
  color: #bbb;
  cursor: pointer;
}

.success-msg {
  background-image: url(../../public/assets/images/icons/success-icon.svg);
  border-left-color: #33ac2e;
}

.success-msg strong {
  color: #33ac2e;
}

.error-msg {
  background-image: url(../../public/assets/images/icons/error-icon.svg);
  border-left-color: #ff0e00;
}

.error-msg strong {
  color: #ff0e00;
}

.video-player-wrapper {
  position: relative;
}

.not-found-msg {
  display: flex;
  width: 100%;
  height: fit-content;
  justify-content: center;
  font-size: 25px;
}

.video-player-container {
  position: absolute;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  right: -100vw;
  top: 0;
  transition: all 1s ease-in;
  /* width: 100%; */
  /* height: 0; */
  /* overflow: hidden; */
  /* transition: all 0.5s ease-in; */
}

.close-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.player-control {
  position: absolute;
  display: flex;
  bottom: 40px;
  width: 100%;
  padding: 0 40px;
  align-items: center;
}

.player-control .play-pause-icon {
  height: 40px;
  width: 40px;
  cursor: pointer;
}

.player-progress-bar {
  width: 100%;
  margin-left: 20px;
  display: flex;
  align-items: center;
}

.video-progress-bar,
.video-progress-bar::-webkit-slider-thumb,
.video-progress-bar:focus {
  width: 100%;
  padding: 0;
  height: 3px;
  -webkit-appearance: none;
  /* Hides the slider so that custom slider can be made */
  width: 100%;
  /* Specific width is required for Firefox. */
  background: transparent;
}

.video-progress-bar::-ms-track {
  width: 100%;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

.video-progress-bar::-webkit-slider-thumb {
  -webkit-appearance: none;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  background: #ffffff;
  cursor: pointer;
  margin-top: -4px;
}

/* All the same stuff for Firefox */
.video-progress-bar::-moz-range-thumb {
  -webkit-appearance: none;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  background: #ffffff;
  cursor: pointer;
  margin-top: -4px;
}

/* All the same stuff for IE */
.video-progress-bar::-ms-thumb {
  -webkit-appearance: none;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  background: #ffffff;
  cursor: pointer;
  margin-top: -4px;
}

.video-progress-bar::-webkit-slider-runnable-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  background: #fff;
  border-radius: 20px;
}

/*Custom Checkbox Radio*/
.custom-checkbox,
.custom-radio {
  position: relative;
  overflow: hidden;
}

.custom-checkbox input[type="checkbox"],
.custom-radio input[type="radio"] {
  position: absolute;
  left: -2000px;
}

.custom-checkbox input[type="checkbox"]+label,
.custom-radio input[type="radio"]+label {
  position: relative;
  color: #414042;
  font-size: 18px;
  line-height: 30px;
  display: inline-block;
  margin-bottom: 0;
}

.custom-checkbox input[type="checkbox"]+label:after,
.custom-radio input[type="radio"]+label:after {
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  border: 2px solid #38519e;
  left: 0;
  top: 5px;
  background: transparent;
}

.custom-radio input[type="radio"]+label:after {
  border-radius: 50%;
}

.ts-qualification {
  display: -webkit-box;
  max-width: 400px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-checkbox input[type="checkbox"]+label span,
.custom-radio input[type="radio"]+label span {
  position: relative;
  padding-left: 35px;
  font-size: 14px;
  color: #414042;
  display: inline-block;
}

.custom-checkbox input[type="checkbox"]:checked+label:after,
.custom-radio input[type="radio"]:checked+label:after {
  background: #38519e url(../../public/assets/images/icons/Check-white.svg) no-repeat center;
  background-size: 16px auto;
}

.custom-radio input[type="radio"]:checked+label:after {
  border-radius: 50%;
}

.checkbox-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.checkbox-wrapper .custom-checkbox {
  width: 50%;
}

/*Switch Checkbox*/
.switch-checkbox {
  position: relative;
}

.switch-checkbox input[type="checkbox"] {
  position: absolute;
  opacity: 0;
}

.switch-checkbox label {
  display: inline-block;
  width: 36px !important;
  position: relative;
  height: 24px;
}

.switch-checkbox label:before {
  background-color: #d8d8d8;
  opacity: 0.7;
  border-radius: 7px;
  content: "";
  position: absolute;
  width: 100%;
  height: 14px;
  left: 0;
  top: 50%;
  margin-top: -7px;
}

.switch-checkbox label:after {
  background-color: #8798ad;
  border-radius: 50%;
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0;
  top: 50%;
  margin-top: -10px;
  transition: all 0.2s ease-in;
}

.switch-checkbox input[type="checkbox"]:checked+label:after {
  background-image: linear-gradient(to right, #56bec0 0%, #3995cf 0%, #3a83c1 27%, #395ba4 84%, #394f9c 100%);
  left: inherit;
  right: 0;
}

.switch-checkbox input[type="checkbox"]:checked+label:before {
  opacity: 1;
}

/* .mega-menu {
  position: absolute;
  top: -110vh;
  right: 0;
  left: 0;
  bottom: 0;
  background-image: linear-gradient(to right, #56bec0 0%, #3995cf 0%, #3a83c1 27%, #395ba4 84%, #394f9c 100%);
  height: 50vh;
  width: 100vw;
  z-index: 1;
  padding: 30px;
  transition: all 0.5s ease-in;
  display: none;
}
.mega-drop-down:hover .mega-menu {
  top: 130px;
  transition: all 0.5s ease-in;
  display: block;
}
.mega-drop-down img {
  padding-left: 5px;
}
.mega-drop-down {
  padding: 35px 0;
}
.menu-items {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(1, 150px);
  gap: 20px;
  width: 100%;
}
.close-btn {
  filter: brightness(0) invert(1);
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.menu-items li {
  list-style: none;
  margin: 0 !important;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}
.menu-label {
  font-weight: 600;
  font-size: 22px;
  line-height: 22px;
  color: #ffffff;
}
.menu-wrapper {
  display: flex;
  height: 100%;
}
.menu-content {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  margin-bottom: 20px;
}
.mega-menu-open li {
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
  color: #ffffff;
}
.menu-content img {
  margin-left: 10px;
}
.menu-label ul {
  display: none;
}
.mega-menu-open {
  display: block !important;
}

.mega-menu-open li:last-child {
  border: none;
}
.mega-menu-open {
  width: 100%;
}
.mega-menu-open li {
  border-bottom: 1px solid #eeeeee45;
  padding: 16px 0;
} */

/*common DropDown*/
.S-drop-down {
  background-color: #fff;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  display: none;
  position: absolute;
}

.S-drop-down:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 8px 6px 8px;
  border-color: transparent transparent #ffffff transparent;
  position: absolute;
  right: 15px;
  top: -6px;
}

.S-drop-down .dropDown-header {
  padding: 20px;
  box-shadow: inset 0 -1px 0 0 #bbb;
}

.S-drop-down .dropDown-footer {
  padding: 10px 20px;
  box-shadow: inset 0 1px 0 0 #bbb;
}

.S-drop-down .dropDown-body {
  padding: 5px 20px;
}

.signup-form-cell label.sp-label,
.common-form-cell label.sp-label {
  font-size: 20px;
  font-weight: 600;
}

.preferred-channel .custom-checkbox:nth-child(2),
.multi-opt>div:not(:first-child) {
  margin-left: 20px;
}

/*Header*/
#header {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  left: 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99;
}

#header:before {
  content: "";
  position: absolute;
  left: 0;
  top: -110%;
  height: 100%;
  width: 100%;
  transition: all 0.5s ease-in;
  z-index: 1;
}

#header .logo img {
  transition: all 0.5s ease-in;
}

#header.headerBg:before {
  background-color: #fff;
  top: 0;
  opacity: 1;
}

#header .container-fluid {
  position: relative;
  z-index: 2;
}

.header-search-container {
  position: fixed;
  top: -210px;
  background-color: rgba(255, 255, 255, 0.96);
  box-shadow: 0 7px 10px 0px rgba(0, 0, 0, 0.05);
  left: 0;
  width: 100%;
  transition: all 0.5s ease-in;
  z-index: 9;
}

.header-wraper {
  padding: 10px 0;
}

.header-search-box {
  max-width: 1230px;
  margin: 0 auto;
  padding: 50px 30px;
  position: relative;
}

.header-search-input {
  background: url(../../public/assets/images/icons/Search.svg) no-repeat left center;
  background-size: 24px auto;
  width: calc(100% - 70px);
}

.header-search-input input[type="text"] {
  -webkit-appearance: none;
  background-color: transparent;
  border: 0;
  font-size: 36px;
  font-weight: 400;
  padding-left: 50px;
  width: 100%;
}

.header-search-input input[type="text"]:focus {
  outline: none;
}

.close-search {
  cursor: pointer;
  position: absolute;
  right: 30px;
  top: 50%;
  margin-top: -12px;
  height: 24px;
  width: 24px;
  animation: closeIcon-rotate-out 0.5s;
}

.mobile-search {
  display: none;
}

@keyframes closeIcon-rotate-in {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
}

@keyframes closeIcon-rotate-out {
  from {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(0deg);
  }
}

.header-search-container.show-search {
  top: 0;
}

.header-search-container.show-search .close-search {
  animation: closeIcon-rotate-in 1s;
  animation-delay: 0.5s;
  -webkit-transform-origin: center;
  transform-origin: center;
}

.top-menu {
  flex: 1;
  margin: 0;
  padding: 0;
}

.top-menu ul li {
  list-style: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.top-menu ul li:not(:first-child) {
  margin-left: 30px;
}

.top-menu ul li a {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  position: relative;
}

.top-menu ul li.has-submenu a {
  background: url(../../public/assets/images/icons/ArrowDown.svg) no-repeat right center;
  padding-right: 22px;
}

.top-menu a:hover,
.sign-in-link:hover {
  color: #3a509c;
  cursor: pointer;
}

.top-menu a:before,
.sign-in-link:before {
  background-color: #3a509c;
  bottom: -5px;
  content: "";
  height: 2px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 0;
  transition: all 0.5s ease-in;
}

.top-menu a:hover:before,
.sign-in-link:hover:before {
  width: 100%;
}

.header-right {
  flex: 1;
}

.header-search {
  padding: 0 20px;
  border-right: 1px solid #414042;
  cursor: pointer;
}

.header-right-links button {
  display: inline-block;
}

.header-right-links button {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  margin-left: 20px;
  position: relative;
  background-color: transparent;
  border: none;
}

.header-right-links button.sign-up-btn {
  color: #fff;
  padding: 13px 20px;
}

/*Login*/
body.login {
  background: url(../../public/assets/images/icons/signIn-bg.svg) no-repeat top right;
  padding-top: 180px;
}

body.login #header.headerBg:before {
  opacity: 1;
}

.login-form-container {
  width: 500px;
}

.login-form {
  margin-top: 40px;
}

.login-form .form-row {
  max-width: 370px;
}

.login-box h1 {
  font-size: 54px;
  font-weight: 300;
  margin-bottom: 0;
}

.login-box p {
  color: #414042;
  font-size: 18px;
  font-weight: 500;
}

.login-box .forget-password-link {
  font-size: 14px;
  font-weight: 500;
  color: #38519e;
}

.form-row label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}

.input-with-icon {
  position: relative;
  max-width: 370px;
  width: 100%;
}

.input-with-icon input {
  background-color: #fff;
  border: 1px solid #bbbbbb;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  color: #414042;
  padding: 16px 10px 16px 45px;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-weight: 500;
}

.input-with-icon input:focus {
  outline: none;
}

.input-with-icon input::placeholder,
.input-with-icon input::-webkit-input-placeholder,
.input-with-icon input::-ms-input-placeholder {
  color: #eee;
}

.login-box .forget-password-link:hover {
  text-decoration: underline;
}

.input-icon {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
  height: 48px;
  width: 24px;
}

.beta-reg-form-container .input-icon {
  z-index: 0;
}

.user-icon {
  padding-left: 45px !important;
  background-image: url("../../public/assets/images/icons/email-icon.svg");
  background-repeat: no-repeat;
  background-position: 15px center;
  background-size: 20px;
}

.password-icon {
  padding-left: 45px !important;
  background-image: url("../../public/assets/images/icons/password-icon.svg");
  background-repeat: no-repeat;
  background-position: 15px center;
}

.input-error-box {
  color: #ff0e00;
  margin-top: 5px;
  font-size: 12px;
  font-weight: 500;
  position: relative;
  max-width: 95%;
  width: 100%;
}

.login-box .common-btn {
  width: 100%;
}

.login-footer {
  max-width: 370px;
}

.login-footer a {
  color: #38519e;
  font-size: 14px;
  font-weight: 500;
}

.login-footer a:nth-child(2) {
  margin-left: 20px;
}

.login-form h5 {
  font-size: 18px;
  font-weight: 600;
}

.login-form h6 {
  font-size: 14px;
  font-weight: 500;
}

.varification-codes input {
  padding: 13px 10px;
  font-size: 16px;
  border-radius: 5px;
  border: solid 1px #bbb;
  line-height: 16px;
  background-color: #fff;
  max-width: 48px;
  text-align: center;
}

.varification-codes input:not(:first-child) {
  margin-left: 10px;
}

.varification-code-info {
  font-size: 14px;
}

.varification-code-info span {
  color: #ff0e00;
  font-weight: 700;
}

.resend-code {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.57;
}

.resend-code a {
  color: #38519e;
}

.cancel-link {
  color: #38519e;
  font-size: 22px;
  font-weight: 600;
  text-decoration: none;
}

.cancel-link:hover {
  text-decoration: underline;
}

.header-space {
  margin-top: 100px;
}

/*Static page*/
.static-page {
  margin-top: 100px;
  margin-bottom: 50px;
}

.static-page h1 {
  font-size: 36px;
  line-height: 46px;
  font-weight: 500;
  margin-bottom: 30px;
}

.static-page p {
  line-height: 26px;
  margin-bottom: 15px;
}

.sub-heading {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 30px;
}

.static-page ul,
.static-page ol {
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.static-page ul li {
  margin-bottom: 10px;
}

.static-page ol li {
  margin-bottom: 15px;
}

.static-page ol li ul {
  list-style: disc;
}

.static-page ol li .static-page ol li ul li {
  margin-bottom: 5px;
}

/* Career Page */
.job-box-wrapper {
  background: #fafafa;
  border: 1px solid #d8d8d8;
  border-radius: 12px;
  padding: 30px;
  margin-bottom: 32px;
}

.job-title {
  color: #38519e;
  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
}

.job-position-text,
.experience-text,
.keyword-text {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #414042;
  margin-right: 18px;
  margin-bottom: 15px;
}

.job-position-number,
.experience-number {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #3a7ebd;
}

.job-title-wrapper {
  margin-bottom: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.number-of-position {
  padding-right: 48px;
  position: relative;
}

.number-of-position::before {
  position: absolute;
  content: "|";
  right: 23px;
  color: #bfbcbc;
}

.job-position-wrapper {
  display: flex;
  margin-bottom: 20px;
}

.keyword-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  flex-wrap: wrap;
}

.job-keywords {
  background: #d5ecfb;
  border: 1px solid #3a83c1;
  border-radius: 24px;
  color: #3a7ebd;
  padding: 5px 17px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin-right: 13px;
  margin-bottom: 15px;
}

.job-description-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #414042;
  margin-bottom: 15px;
}

.job-description {
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #000;
}

.job-description ul {
  list-style-position: inside;
}

.job-description-wrapper {
  margin-bottom: 20px;
}

.job-apply-btn button {
  color: #fff;
  padding: 13px 20px;
}

.job-apply-btn {
  display: flex;
  justify-content: flex-end;
}

.job-location {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #8a919b;
  padding: 10px 20px;
  border: 1px solid #d8d8d8;
  border-radius: 50px;
}

.career-form {
  width: 100%;
}

.job-location-text {
  display: flex;
  align-items: center;
}

/*Contact Us*/
.contact-us {
  padding: 50px 0;
  background: url(../../public/assets/images/bg.svg) no-repeat right top;
  padding-bottom: 100px;
  background-position: left;
}

.contact-form-container {
  width: 45%;
}

.contact-us-address {
  width: 45%;
  margin-left: auto;
}

.map-wrapper p {
  height: 100%;
}

.contact-form-cta {
  display: flex;
  justify-content: flex-end;
}

.contact-form-cta .common-btn {
  min-width: 200px;
  display: inline-block;
  height: fit-content;
}

.contact-form-cta .common-btn:nth-child(2) {
  margin-left: 20px;
}

.contact-us-address p {
  margin-bottom: 10px;
}

.contact-textarea {
  width: 100%;
  border: 1px solid #bbbbbb;
  border-radius: 5px;
}

.form-error-msg {
  font-size: 13px;
  color: #ff0000;
}

/*DashBoard*/
.dasboard-continer {
  width: 100%;
  min-height: calc(100vh - 141px);
}

/*Right main container*/
.dashboard-right-container {
  flex: 1;
  transition: all 0.5s ease-in;
  position: relative;
}

.dr-right-contaier {
  display: flex;
  align-items: center;
}

.dr-container-heading-sec {
  display: flex;
  padding: 25px 0;
}

.dr-container-heading-sec h1 {
  color: #414042;
  font-size: 36px;
  line-height: 46px;
  font-weight: 500;
}

.filter-btn {
  /* background: #3d92db url(../images/filter-icon.svg) no-repeat 15px center; */
  border-radius: 25px;
  color: #fff;
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;
  border: 0;
  appearance: none;
  padding: 10px 15px 10px 45px;
}

.filter-dropDown {
  width: 460px;
  display: block;
  right: 0;
  top: 50px;
}

.filter-dropDown .dropDown-header,
.filter-dropDown .dropDown-footer {
  box-shadow: none;
}

.filter-dropDown input[type="text"] {
  background-color: #eff1f1;
  width: 100%;
  padding: 5px;
  border: 0;
}

.filter-dropDown h6 {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

.filter-dropDown .common-btn {
  margin-left: 30px;
}

.filter-form-row .filter-form-cell1 {
  width: 250px;
}

.filter-form-row .filter-form-cell2 {
  width: 150px;
}

.filter-single-element {
  border: 1px solid #c4c9cf;
  border-radius: 2px 2px 0 0;
}

.filter-single-element>label {
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  padding: 10px;
  border-bottom: 1px solid #c4c9cf;
  display: block;
}

.filter-single-element .filer-option-box {
  padding: 10px;
}

.add-webiner-btn {
  margin-right: 15px;
}

.add-webiner-btn .common-btn {
  font-size: 12px;
  padding: 10px 15px;
  font-weight: 400;
}

.filer-option-box .custom-checkbox input[type="checkbox"]+label span,
.filer-option-box .custom-radio input[type="radio"]+label span {
  font-size: 12px;
  line-height: 22px;
}

.member-table thead tr th:nth-child(3),
.member-table tbody tr td:nth-child(3) {
  text-align: center;
}

.member-table tbody tr td span.active {
  background-color: #cafccc;
  display: inline-block;
  padding: 5px;
  color: #285329;
}

.member-table tbody tr td span.expired {
  background-color: #ffe3da;
  display: inline-block;
  padding: 5px;
  color: #eb440f;
}

.tabel-cta a {
  display: inline-block;
  width: 24px;
}

.tabel-cta a:not(:first-child) {
  margin-left: 10px;
}

.member-table th:first-child,
.member-table td:first-child {
  width: 150px;
}

/*Telescientest page*/

.ts-layout {
  width: 100%;
  overflow: visible;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
}

.ts-layout h6 {
  font-size: 12px;
  font-weight: 600;
}

.ts-layout p {
  font-size: 11px;
  font-weight: 500;
}

.ts-layout .ts-image {
  cursor: pointer;
}

.ts-layout .sub-disease-ts {
  box-shadow: none;
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  padding: 0px;
  line-height: 20px;
}

.ts-layout li {
  list-style-type: square;
  margin-bottom: 5px;
}

.ts-layout ul {
  margin-left: 35px;
  margin-top: 10px;
}

.ts-list {
  margin: 0;
  flex-wrap: wrap;
  position: relative;
}

.ts-list>li {
  border-radius: 24px;
  border: 1px solid #dbd7da;
  background-color: #fff;
  padding: 10px;
  width: 31.5%;
  margin-right: 30px;
  margin-bottom: 30px;
  list-style: none;
  max-width: 32%;
}

.ts-list>li:nth-child(3n) {
  margin-right: 0;
}

.single-ts-img {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.ts-sort-details {
  padding: 0 10px 10px 10px;
}

.ts-sort-details h4 {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.ts-sort-details h3 {
  font-size: 14px;
  margin: 0;
}

b {
  font-weight: 600;
}

.ts-sort-details p {
  font-size: 11px;
  font-weight: 500;
  margin-top: 2px;
  margin-bottom: 0;
  color: #3e4552;
}

.ts-name {
  color: #38519e;
  font-size: 12px;
  cursor: pointer;
}

.single-cta {
  padding: 0 10px 10px 0;
}

.single-cta a {
  font-size: 11px;
  font-weight: 500;
  text-decoration: underline;
  color: #38519e;
}

.session-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.session-card .session-text {
  color: #3a7ebd;
  font-weight: 500;
  font-size: 12px;
  max-width: 93px;
  text-align: center;
  line-height: 13px;
  margin: 10px 0;
}

.session-card .session-price {
  color: #8a919b;
  font-weight: 500;
  font-size: 11px;
  max-width: 100px;
  text-align: center;
}

.session-card img {
  border-radius: 5px;
  height: 30px;
  width: 30px;
  padding: 2px;
  aspect-ratio: 1;
}

/*Telescientest profile details*/
.ts-detail-overlay {
  background-color: rgba(255, 255, 255, 0.7);
  position: fixed;
  height: 100vh;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 98;
  transition: all 0.5s ease-in-out;
  display: none;
}

.ts-detail-overlay.show-ts-profile-details {
  display: block;
}

.ts-detials-container {
  box-shadow: -10px 0 10px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
  position: fixed;
  right: -470px;
  top: 0;
  width: 95%;
  max-width: 470px;
  padding: 0;
  height: 100vh;
  transition: all 0.5s ease-in-out;
  z-index: 99;
}

.ts-detials-container.show-ts-profile-details {
  right: 0;
}

.ts-detials-container p {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
}

.ts-detial-body {
  margin-top: 75px;
  height: calc(100vh - 235px);
  overflow-y: auto;
  padding: 0px 30px 0;
}

.ts-short-profile {
  padding-bottom: 20px;
  border-bottom: 1px solid #bbb;
}

.ts-short-profile-pic {
  width: 100px;
  border-radius: 8px;
  overflow: hidden;
}

.ts-short-profile-content {
  margin-left: 20px;
  width: 70%;
}

.ts-short-profile-content h5,
.ts-profile-title {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.close-profile {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;

  height: 20px;
  width: 20px;
}

.ts-profile-cta {
  background-color: #fff;
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0;
  padding: 20px 30px;
}

.ts-profile-cta .common-btn {
  width: 100%;
}

.ts-profile-cta .common-btn:nth-child(2) {
  margin-top: 20px;
}

.ts-profile-qf-list {
  border-bottom: 1px solid #bbb;
  word-wrap: break-word;
}

.ts-profile-sub-title {
  font-size: 16px;
  font-weight: 700;
}

.ts-profile-review li {
  list-style: none;
  padding-bottom: 15px;
  padding-top: 15px;
}

.ts-profile-review li:not(:first-child) {
  border-top: 1px solid #bbb;
}

.show-full-review span {
  font-size: 14px;
  font-weight: 500;
  color: #38519e;
  text-align: right;
  display: inline-block;
  cursor: pointer;
}

.review-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/*Home Page Top Banner*/
.home-banner {
  background-size: cover;
  min-height: 70vh;
  position: relative;
  transition: all 1s ease-in;
  height: 690px;
  background-position-y: 60px;
}

.home-banner.animatedParent {
  overflow: visible;
}

.banner-content-box {
  padding-top: 285px;
  margin-left: -30px;
}

.banner-content-box h3 {
  font-size: 22px;
  font-weight: 500;
}

.banner-content-box h1 {
  font-size: 34px;
  font-weight: 700;
  line-height: 1.26;
  text-indent: -4px;
  margin-bottom: 5px;
  color: #354f97;
}

.banner-title.second-title {
  padding-left: 150px;
  font-size: 46px;
}

.banner-content-box p {
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.56;
}

.banner-cta {
  margin-top: 20px;
  text-align: right;
  position: relative;
  float: right;
}

.banner-cta button.common-btn {
  border-radius: 50px;
  font-size: 22px;
  padding-top: 16px;
  padding-bottom: 16px;
  width: 325px;
}

.banner-cta .banner-button-subtitle {
  font-size: 15px;
  font-weight: 300;
}

.banner-cta .video-image {
  height: 94px;
  position: absolute;
  right: 0;
  bottom: 82px;
  display: none;
  border: 3px solid #000;
}

.banner-cta:hover .video-image {
  display: block;
}

.count-section {
  bottom: -38px;
  background-image: linear-gradient(to right, #56bec0 0%, #3995cf 0%, #3a83c1 27%, #395ba4 84%, #394f9c 100%);
  border-radius: 10px;
  max-width: 1200px;
  left: 0;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
  position: absolute;
  right: 0;
}

.count-section ul {
  margin: 0;
  padding: 10px 0;
}

.count-section ul li {
  color: #fff;
  list-style: none;
  min-width: 33.33%;
  text-align: center;
}

.count-section ul li:nth-child(2) {
  border-left: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
}

.count-section ul li strong {
  display: block;
  font-size: 28px;
  font-weight: 600;
}

/*Home How It Work*/

.home-how-it-work .container {
  /*background: url(../images/how-it-work-img.jpg) no-repeat bottom right;*/
  padding-bottom: 50px;
  padding-top: 50px;
  position: relative;
  z-index: 3;
}

.home-how-it-work {
  background-color: #f9f9f9;
}

.home-how-it-work-props {
  /* background: url(../../public/assets/images/how-it-work-img.jpg) no-repeat bottom right; */
  position: absolute;
  left: 100px;
  bottom: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  background-size: cover !important;
}

.home-how-it-work-props img {
  max-width: 100%;
  margin-left: auto;
}

.home-how-it-work h3,
.home-how-it-work h4 {
  width: 100%;
}

.home-how-it-work .section-heading {
  margin-bottom: 0;
}

.how-it-work-content {
  max-width: 50%;
}

.how-it-work-content ul {
  margin: 40px 0 0;
  padding: 0;
}

.how-it-work-content ul li {
  list-style: none;
  display: flex;
  margin-bottom: 35px;
}

.how-it-work-content ul li .home-how-it-work-icon {
  height: 70px;
  min-width: 70px;
}

.how-it-work-content ul li h5 {
  font-size: 18px;
  line-height: 1.56;
  font-weight: 600;
  margin-bottom: 5px;
}

.home-how-it-work-text {
  padding-left: 30px;
}

.home-how-it-work-text h5 {
  cursor: pointer;
}

.home-how-it-work-cta {
  padding-top: 10px;
  padding-left: 100px;
}

.home-how-it-work-cta .common-btn {
  padding: 15px 70px;
}

/*What is Telescience?*/
.what-is-telescience {
  /*background-color: #edf5ff;*/
  padding: 50px 0 0;
  overflow: hidden;
}

.what-is-telescience-row {
  position: relative;
}

.what-is-telescience-content {
  position: absolute;
  background-color: #fff;
  z-index: 2;
}

.what-is-telescience-row.row1 {
  margin-bottom: 30px;
}

.what-is-telescience-row.row1 .telescience-img-box {
  border-radius: 10px 100px 10px 10px;
  max-width: 570px;
  overflow: hidden;
}

.what-is-telescience-content {
  background-color: #fff;
  font-size: 22px;
  line-height: 36px;
  font-weight: 500;
  max-width: 680px;
  padding: 55px 60px;
  overflow: hidden;
  position: absolute;
  box-shadow: 0px 6px 20px 3px #00000030;
}

.what-is-telescience-row.row1 .what-is-telescience-content {
  border-radius: 100px 10px 10px 10px;
  top: 150px;
  right: 16px;
}

.what-is-telescience-row.row2 .telescience-img-box {
  border-radius: 100px 10px 10px 10px;
  max-width: 570px;
  overflow: hidden;
}

.what-is-telescience-row.row2 .what-is-telescience-content {
  border-radius: 10px 100px 10px 10px;
  top: 150px;
  left: 16px;
}

p.telescience-title {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
}

p.telescience-content {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
}

.telescience-icon {
  height: 69px;
  position: absolute;
  right: 35px;
  bottom: 9px;
}

/*Why Synaptical*/

.why-synaptical {
  padding: 90px 0 52px;
  background-size: 100% auto;
  min-height: 500px;
  position: relative;
}

.why-synaptical .container {
  position: relative;
  z-index: 3;
}

.inspiring-story-section {
  background-image: linear-gradient(to right, #56bec0 0%, #3995cf 0%, #3a83c1 27%, #395ba4 84%, #394f9c 100%);
  padding: 20px 50px;
  border-radius: 30px;
  height: max-content;
}

.client-story {
  padding-bottom: 40px;
  color: #fff;
}

.why-synaptical .section-sub-heading {
  max-width: 50%;
}

.inspiring-story-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 40%;
}

.inspiring-story-section h2 {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 10px;
}

.client-name {
  color: #fff;
  margin-bottom: 10px;
}

.inspiring-story-section .swiper-pagination-bullet.swiper-pagination-bullet-active.swiper-pagination-bullet-active-main {
  background-color: #fff;
  height: 13px;
  width: 13px;
}

.inspiring-story-section .swiper-pagination-bullet.swiper-pagination-bullet-active-next,
.inspiring-story-section .swiper-pagination-bullet-active-prev,
.inspiring-story-section .swiper-pagination-bullet.swiper-pagination-bullet-active-next-next,
.inspiring-story-section .swiper-pagination-bullet.swiper-pagination-bullet-active-prev-prev {
  background-color: #fff;
  opacity: 1;
  height: 10px;
  width: 10px;
}

.inspiring-story-button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.swiper-pagination-bullets.swiper-pagination-horizontal.swiper-pagination-bullets-dynamic {
  width: 85px !important;
}

/*Home page Services section*/
.home-services {
  padding: 0 0 50px;
}

.home-services-table table thead tr th {
  border-bottom: 2px solid #38519e;
  padding: 20px 0;
  text-align: center;
}

.home-services-table table thead tr th h6 {
  font-size: 24px;
  color: #414042;
  font-weight: 600;
  text-align: left;
}

.home-services-table table tbody tr td {
  border-bottom: 1px solid #38519e;
  font-size: 16px;
  vertical-align: middle;
  padding: 10px 0;
  text-align: center;
}

.home-services-table table tbody tr td img {
  display: block;
  margin: 0 auto;
  max-width: 16px;
}

.home-services-table table tbody tr td:first-child {
  text-align: left;
}

.home-membership {
  font-size: 24px;
  color: #414042;
  font-weight: 600;
}

.home-membership span {
  color: #414042;
  font-size: 14px;
  font-weight: 600;
  position: relative;
  top: -10px;
}

.home-membership strong {
  color: #414042;
  display: block;
  font-size: 14px;
  font-weight: 500;
}

.home-services-table table .price {
  color: #414042;
  font-size: 18px;
  font-weight: 700;
}

.home-services-table table tfoot tr td {
  padding: 10px 0;
}

.home-services-table table tfoot tr td:not(:first-child) {
  text-align: center;
}

.home-services-table table tfoot .home-membership strong {
  font-size: 14px;
  font-weight: 500;
}

.home-services-mobile {
  display: none;
}

.home-services-mobile .home-membership {
  background-image: linear-gradient(to right, #56bec0 0%, #3995cf 0%, #3a83c1 27%, #395ba4 84%, #394f9c 100%);
  padding: 15px;
  margin-bottom: 25px;
  text-align: center;
}

.home-services-mobile .home-membership,
.home-services-mobile .home-membership span,
.home-services-mobile .home-membership strong {
  color: #fff;
}

.home-services-mobile {
  padding: 50px 0;
}

.home-services-mobile h3 {
  font-size: 36px;
  margin: 0 0 10px;
}

.home-services-card {
  padding: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  margin-top: 20px;
}

.home-services-card ul {
  margin: 0;
  padding: 0 20px 15px;
}

.home-services-card ul li {
  background: url(../../public/assets/images/icons/Check.svg) no-repeat 0 1px;
  margin: 0 0 20px 0;
  padding: 0 0 0 35px;
  font-size: 16px;
  list-style: none;
}

.service-close-icon {
  filter: grayscale(1);
  height: 22px;
  width: 22px;
}

/*Our Telescientists*/
.home-our-tele-scientists {
  background-color: #edf5ff;
  padding: 0;
  margin-top: 100px;
  position: relative;
}

.home-our-tele-scientists:before {
  background: url(../../public/assets/images/our-telescientists-to-bg.svg) no-repeat top center;
  background-size: 100% auto;
  content: "";
  min-height: 150px;
  position: absolute;
  left: 0;
  top: -70px;
  width: 100%;
}

.home-our-tele-scientists .container {
  position: relative;
  z-index: 2;
}

.telescientists-tab-with-slider {
  padding-top: 30px;
}

.telescientists-tab-section {
  max-width: 280px;
  width: 100%;
  margin-right: 50px;
  background-color: #edf5ff;
}

.telescientists-tab-section ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.telescientists-tab-section ul li {
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  padding: 0 50px 0 0;
  list-style: none;
  margin-bottom: 25px;
}

.telescientists-tab-section ul li:hover,
.telescientists-tab-section ul li.active-tab {
  background: url(../../public/assets/images/right-line-arrow-blue.svg) no-repeat right center;
  background-size: 16px auto;
  color: #38519e;
  text-decoration: underline;
}

.home-tele-scientists-slider {
  flex: 1;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.home-tele-scientists-slider .swiper {
  width: 100%;
  padding-bottom: 90px;
}

.home-tele-scientists-slider .swiper-slide {
  padding: 10px;
}

.home-tele-scientists-pic {
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}

.home-tele-scientists-pic img {
  max-width: 100%;
}

.home-tele-scientists-slider .swiper-slide {
  position: relative;
}

.home-tele-scientists-slider-content {
  margin-top: 0;
  max-width: 100%;
}

.home-tele-scientists-name-exp,
.home-tele-scientists-qualifications,
.home-tele-scientists-ratings {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
  padding: 15px 20px;
}

.home-tele-scientists-qualifications {
  min-height: 300px;
  position: relative;
}

.home-tele-scientists-block-heading {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.home-tele-scientists-name-exp p,
.home-tele-scientists-qualifications p {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
  padding: 0;
}

.home-tele-scientists-name-exp {
  margin-top: 15px;
}

.home-tele-scientists-exp {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;
}

.hts-book-now {
  margin-top: 15px;
}

.hts-book-now button {
  display: block;
  width: 100%;
}

.show-all-rating {
  margin-top: 15px;
  padding-bottom: 10px;
  text-align: right;
}

.show-all-rating a {
  color: #38519e;
  font-size: 18px;
  font-weight: 700;
  padding-right: 20px;
  background: url(../../public/assets/images/icons/more-rating-arrow.svg) no-repeat right center;
}

.home-tele-scientists-qualifications {
  margin-top: 0px;
}

.home-tele-scientists-qualifications ol {
  list-style: none;
  margin: 0;
}

.home-tele-scientists-qualifications ol li {
  padding-top: 15px;
}

.home-tele-scientists-qualifications ol li li {
  list-style: inside !important;
}

.home-tele-scientists-qualifications ol li:not(:first-child) {
  margin-top: 15px;
  border-top: solid 1px #bbb;
}

.home-qualifiction-title {
  font-size: 16px;
  font-weight: 600;
}

.award-links a {
  word-wrap: break-word;
  word-break: break-all;
}

.award-links {
  word-wrap: break-word;
}

.home-qualifiction-title-no {
  display: inline-block;
  min-width: 25px;
}

.hts-left {
  max-width: 370px;
  width: 100%;
}

.hts-right {
  flex: 1;
  margin-left: 30px;
}

.home-tele-scientists-ratings {
  margin-top: 15px;
}

.home-tele-scintist-total-rating {
  color: #fecc01;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.total-no-review {
  color: #38519e;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  margin-left: 5px;
}

.tele-scientists-ratings-list {
  list-style: none;
}

.tele-scientists-single-rating {
  position: relative;
  padding: 10px 0;
}

.tele-scientists-ratings-list li {
  border-bottom: solid 1px #bbbbbb;
}

.tele-scientists-ratings-list li:first-child {
  border: none !important;
}

.tele-scientists-single-rating .user-name {
  font-size: 16px;
  font-weight: 600;
  padding-right: 20px;
  position: relative;
  margin-bottom: 5px;
}

.tele-scientists-single-rating .user-name span {
  display: inline-block;
  margin-left: 5px;
  color: #2fd371;
  font-weight: 500;
}

.tele-scientists-single-rating p {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

.home-tele-scientists-slider .swiper-pagination {
  position: absolute;
  bottom: 6px;
  height: 24px;
}

.home-tele-scientists-slider .swiper-pagination-current {
  color: #414042;
  font-size: 24px;
  font-weight: 500;
}

.home-tele-scientists-slider .swiper-button-next,
.home-tele-scientists-slider .swiper-button-prev {
  position: absolute;
  bottom: 0;
  top: inherit;
  height: 30px;
  width: 65px;
  border-radius: 15px;
  background-image: linear-gradient(to right, #56bec0 0%, #449dd0 100%);
}

.home-tele-scientists-slider .swiper-button-next:before,
.home-tele-scientists-slider .swiper-button-prev:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: url(../../public/assets/images/ts-slider-arrow.svg) no-repeat center;
  height: 100%;
  width: 100%;
}

.home-tele-scientists-slider .swiper-button-next:after,
.home-tele-scientists-slider .swiper-button-prev:after {
  display: none;
}

.home-tele-scientists-slider .swiper-button-prev:before {
  transform: rotate(180deg);
}

.home-tele-scientists-slider .swiper-button-prev {
  left: 0;
}

.home-tele-scientists-slider .swiper-button-next {
  right: 0;
}

.telescientists-dropdown {
  display: none;
  background-image: linear-gradient(to right, #56bec0 0%, #3995cf 0%, #3a83c1 27%, #395ba4 84%, #394f9c 100%);
  padding: 15px;
  margin-bottom: 25px;
  text-align: center;
  color: #fff;
  padding-right: 35px;
  font-size: 18px;
  text-align: left;
  position: relative;
}

/*Telescientist Session Costs*/
.HT-cost {
  background-color: #fff;
  padding: 50px 0;
}

.ht-cost-container {
  flex-wrap: wrap;
}

.ht-cost-pack {
  border-radius: 10px;
  border: solid 1px #bbb;
  padding: 20px;
  width: calc(33.33% - 60px / 3);
}

.ht-cost-pack:not(:first-child) {
  margin-left: 30px;
}

.ht-cost-header {
  padding-bottom: 20px;
  border-bottom: solid 1px #bbbbbb;
  text-align: center;
}

.ht-cost-header h5 {
  font-size: 18px;
  font-weight: 500;
  color: #4c77b3;
}

.ht-pack-price sup {
  font-size: 14px;
  font-weight: 600;
  position: relative;
  top: -20px;
}

.ht-pack-price strong {
  color: #414042;
  font-size: 36px;
  font-weight: 700;
}

.ht-costPP {
  color: #bbbbbb;
  font-size: 14px;
  font-weight: 600;
}

.ht-cost-body {
  padding-top: 20px;
}

.ht-cost-body h6 {
  font-size: 18px;
  font-weight: 700;
}

.ht-cost-body ul {
  margin-top: 20px;
  padding: 0;
}

.ht-cost-body ul li {
  background: url(../../public/assets/images/icons/Check.svg) no-repeat 0 5px;
  min-height: 40px;
  background-size: 16px auto;
  list-style: none;
  padding-left: 30px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.25px;
}

.ht-cost-cta {
  text-align: center;
}

.ht-cost-cta .common-btn {
  min-width: 70%;
  display: inline-block;
}

.ht-cost-note {
  text-align: center;
  margin-right: 20px;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 500;
}

/*In Partnership With*/
.home-Partnership {
  background-color: #edf5ff;
  padding: 50px 0 20px 0;
}

.home-Partnership .swiper {
  padding: 0 20px;
}

.home-Partnership .swiper-button-next,
.home-Partnership .swiper-button-prev {
  width: 30px !important;
}

.home-partnership-slider {
  padding: 100px 0;
}

.home-partnership-slider-container {
  max-width: 1360px;
  margin: 0 auto;
  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
}

#home-partnership-slider {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0 60px;
}

#home-partnership-slider .swiper-slide {
  display: flex;
  justify-content: center;
  padding: 0 10px;
  align-items: center;
  min-height: 150px;
}

#home-partnership-slider .swiper-slide img {
  max-width: 100%;
}

#home-partnership-slider .swiper-button-next,
#home-partnership-slider .swiper-button-prev {
  background: #edf5ff;
  height: 100%;
  top: 0;
  width: 60px;
  margin-top: 0;
  display: flex;
  align-items: center;
  justify-content: inherit;
  color: #000;
}

/*#home-partnership-slider .swiper-button-next,
#home-partnership-slider .swiper-button-prev:after{background: #fff url(../images/ArrowForward.svg) no-repeat center;}*/
#home-partnership-slider .swiper-button-prev {
  left: 0;
}

#home-partnership-slider .swiper-button-next {
  right: 0;
  justify-content: flex-end;
}

#home-partnership-slider .swiper-pagination {
  display: none;
}

/*Inspiring Stories*/
.home-inspiring-stories {
  background-color: #fff;
  padding: 50px 0;
}

.story-author {
  font-size: 18px;
  position: relative;
  margin-top: 10px;
  font-weight: 600;
}

.single-story-content {
  padding: 20px 0 40px;
}

.single-story-content p {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
}

.paragraph-wrapper p {
  margin-bottom: 15px;
}

.home-inspiring-story-get-started {
  padding: 15px 0 0;
}

.home-inspiring-story-get-started {
  border-top: 1px solid #bbbbbb;
}

.home-inspiring-story-get-started p {
  font-size: 18px;
  font-weight: 500;
}

.home-inspiring-story-get-started .common-btn {
  margin-top: 20px;
}

.home-inspiring-stories .swiper-button-next,
.home-inspiring-stories .swiper-button-prev {
  position: absolute;
  bottom: 30px;
  top: inherit;
  height: 0;
  border-radius: 15px;
}

.slider-text {
  position: absolute;
  bottom: 18px;
  width: 100%;
  text-align: center;
  left: 0;
  font-weight: 600;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 23px !important;
  font-weight: bold;
  color: #000;
}

.home-inspiring-stories .swiper-button-next {
  right: 30%;
}

.home-inspiring-stories .swiper-button-prev {
  left: 30%;
}

.single-story .swiper-wrapper {
  padding-bottom: 50px;
}

/*Consult our Tele Scientists*/
.home-consult-wrapper {
  position: relative;
}

.home-consult-bg-image {
  position: absolute;
  top: -108px;
  right: 0;
  bottom: 0;
}

.home-consult-bg-image img {
  max-height: -webkit-fill-available;
  object-fit: cover;
  height: 100%;
}

.home-consult-scienctists {
  background-image: linear-gradient(to right, #56bec0 0%, #449dd0 100%);
  color: #fff;
  padding: 100px 0;
  min-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
}

.home-consult-scienctists::before {
  background: url(../../public/assets/images/consult-scientists-bg-logo.svg) no-repeat center left;
  background-size: auto 100%;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.home-consult-scienctists::after {
  /* background: url(../../public/assets/images/consult-scientists.png) no-repeat bottom right; */
  background-size: auto 100%;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 115%;
  width: 100%;
}

.home-consult-scienctists .container {
  max-width: 670px;
  position: relative;
  z-index: 2;
}

.home-consult-scienctists-que {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}

.home-consult-scienctists h4 {
  color: #fff;
  font-size: 36px;
  font-weight: 500;
}

.consult-scienctists-cta {
  margin-top: 30px;
}

.support-tele {
  margin-top: 50px;
}

.single-support-tele {
  text-align: center;
}

.single-support-tele img {
  display: inline-block;
}

.single-support-tele p {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
}

.single-support-tele:nth-child(2) {
  margin-left: 50px;
}

footer {
  background-color: #edf5ff;
  display: block;
  padding: 50px 0 0;
}

.footer-container {
  border-bottom: 1px solid rgba(56, 81, 158, 0.3);
  width: 100%;
  padding-bottom: 25px;
}

.footer-left-container {
  width: 370px;
}

.footer-logo {
  margin-bottom: 20px;
}

.footer-logo img {
  max-width: 250px;
}

.footer-left-container p {
  color: #414042;
  font-size: 14px;
  font-weight: 500;
}

.footer-media {
  margin-top: 20px;
  list-style: none;
}

.footer-media li {
  margin: 0;
  padding: 0;
  min-width: 27px;
}

.footer-media img {
  height: 26px;
  max-width: 100%;
  cursor: pointer;
  max-width: 24px;
}

.footer-media li:not(:first-child) {
  margin-left: 30px;
}

.footer-right-container {
  padding-left: 30px;
  flex: 1;
}

.footer-menu {
  margin: 0;
  padding: 0;
  justify-content: space-between;
}

.footer-menu li {
  list-style: none;
  padding: 0;
}

.footer-menu li h5 {
  color: #414042;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 15px;
}

.footer-menu li a {
  color: #38519e;
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;
  text-decoration: none;
}

.footer-menu li a:hover {
  text-decoration: underline;
}

.footer-menu li a:not(:first-child) {
  margin-top: 10px;
}

.newsletter-container {
  width: 100%;
  max-width: 590px;
  margin-top: 35px;
}

.newsletter-container h5 {
  color: #414042;
  font-size: 18px;
  font-weight: 600;
}

.newsletter-container p {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.57;
  margin-bottom: 10px;
}

.newsletter-input {
  border: solid 1px #414042;
  border-radius: 25px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.newsletter-input input:-webkit-autofill {
  background-color: transparent;
}

.newsletter-input img {
  margin-right: 25px;
  cursor: pointer;
  max-width: 16px;
}

.newsletter-input input[type="text"],
.newsletter-input input[type="email"] {
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  padding: 15px 45px 15px 20px;
  font-size: 16px;
  color: #414042;
  width: 100%;
}

.newsletter-input input[type="text"]:focus,
.newsletter-input input[type="email"]:focus {
  outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
  background-color: #edf5ff !important;
  color: #555 !important;
  -webkit-box-shadow: 0 0 0 1000px #edf5ff inset !important;
  -webkit-text-fill-color: #555555 !important;
}

.footer-bottom {
  padding: 30px 0;
  justify-content: space-between;
}

.copyright {
  color: #414042;
  font-size: 14px;
  font-weight: 500;
}

.powered_by {
  flex-direction: column;
}

.powered_by_text {
  margin-top: 5px;
  color: #414042;
  font-size: 15px;
  font-weight: 600;
}

.footer-bottom-links ul li {
  list-style: none;
}

.footer-bottom-links ul li a {
  color: #38519e;
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  padding: 0 10px;
  text-decoration: none;
}

.footer-bottom-links ul li a:hover {
  text-decoration: underline;
}

.footer-bottom-links ul li:not(:first-child) a {
  border-left: solid 1px #7e8392;
}

/* Mission Vision & Value */
.mission-heading {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  margin-top: 15px;
}

.mission-content {
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  color: #414042;
  margin-top: 10px;
}

/* Breast Cancer */
.breast-cancer-page-title {
  margin-top: 30px;
}

.breast-cancer-info li {
  list-style-position: inside;
}

.breast-cancer-section-heading {
  margin-bottom: 10px;
}

.breast-cancer-section-sub-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #414042;
  margin-bottom: 30px;
}

.breast-cancer-list {
  list-style: none;
  margin: 0;
}

.breast-cancer-content-wrapper {
  display: flex;
  gap: 30px;
}

.breast-cancer-content {
  width: 60%;
}

.breast-cancer-image {
  width: 40%;
}

.breast-cancer-title {
  font-weight: 500;
  font-size: 26px;
  line-height: 35px;
  color: #000000;
  margin-bottom: 20px;
}

.breast-cancer-info {
  font-size: 16px;
  line-height: 24px;
  color: #414042;
}

.breast-cancer-image img {
  border-radius: 20px;
}

.breast-cancer-list>li {
  border-bottom: 1px solid #eee;
  padding: 50px 0;
}

.breast-cancer-list>li:first-child {
  padding-top: 0;
}

/*SIgn Up*/
.signup-container {
  max-width: 870px;
  margin: 0 auto 50px;
}

.signup-step-count {
  position: absolute;
  left: -100px;
  top: 60px;
}

.signup-step-count span {
  display: block;
  font-size: 14px;
  font-weight: 600;
}

.signup-step-count strong {
  display: block;
  font-size: 24px;
  font-weight: 700;
}

.signup-heading {
  font-size: 54px;
  font-weight: normal;
}

.signup-sub-heading {
  font-size: 24px;
  font-weight: bold;
}

.sign-up-single-plan {
  border: solid 1px #bbbbbb;
  border-radius: 5px;
  padding-bottom: 20px;
  max-width: calc(50% - 30px / 2);
  width: 100%;
}

.sign-up-price-box {
  padding: 5px 15px 20px 15px;
  text-align: center;
  font-size: 48px;
  font-weight: 700;
  color: #fff;
}

.sign-up-price-box sup {
  font-size: 14px;
  font-weight: 500;
  position: relative;
  top: -5px;
}

.sign-up-price-box .mm {
  font-size: 12px;
  font-weight: 500;
  display: block;
}

.free-plan {
  background-color: #38519e;
}

.signup-features-list {
  padding: 0 20px;
  min-height: 344px;
}

.signup-features-list li {
  background: url(../../public/assets/images/icons/Check.svg) no-repeat top 10px right;
  background-size: 16px auto;
  font-size: 11px;
  font-weight: 500;
  list-style: none;
  border-top: solid 1px #bbbbbb;
  padding: 7px 30px 7px 0;
}

.sign-up-cta {
  text-align: center;
}

.signup-features-list li:first-child {
  border-top: 0;
}

.sign-up-cta .common-btn {
  width: 270px;
  font-size: 16px;
  font-weight: 500;
}

.premium-plan {
  background-image: linear-gradient(to right, #56bec0 0%, #3995cf 0%, #3a83c1 27%, #395ba4 84%, #394f9c 100%);
}

.sign-up-single-plan:nth-child(2) {
  margin-left: 30px;
}

/*Signup Step1*/
.signup-form-container {
  max-width: 870px;
  margin: 0 auto 50px;
  position: relative;
}

.signup-step-num {
  border: solid 1px #414042;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  font-size: 14px;
  font-weight: 500;
  margin-right: 12px;
}

.signup-top-steps {
  position: sticky;
  background-color: #fff;
  top: 140px;
  z-index: 9;
  width: 100%;
  padding-bottom: 25px;
  padding-top: 10px;
}

.signup-top-steps ul li {
  font-size: 14px;
  font-weight: 500;
}

.signup-top-steps ul li:not(:first-child) {
  margin-left: 40px;
}

.active-signup-step {
  color: #38519e;
}

.active-signup-step .signup-step-num {
  border-color: #394f9c;
  background-image: linear-gradient(to right, #56bec0 0%, #3995cf 0%, #3a83c1 27%, #395ba4 84%, #394f9c 100%);
  color: #fff;
}

.signup-single-step h3 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
}

.completed-signup-step {
  color: #33ac2e;
}

.completed-signup-step .signup-step-num {
  border-color: #33ac2e;
  background: #33ac2e url(../../public/assets/images/icons/Check-white.svg) no-repeat center center;
  background-size: 10px auto;
  color: #fff;
  text-indent: -99999px;
}

.signup-form-row,
.form-row,
.common-form-row {
  margin-bottom: 25px;
}

.signup-form-cell,
.common-form-cell {
  width: 100%;
}

.two-col-row .signup-form-cell,
.two-col-row .common-form-cell {
  width: calc(50% - 30px / 2);
}

.signup-internal-row {
  width: 100%;
}

.signup-form-cell label,
.common-form-cell label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}

.signup-form-cell label .opt-field,
.common-form-cell label .opt-field {
  color: #8798ad;
  font-size: 12px;
}

.signup-form-cell:not(:first-child) {
  margin-left: 30px;
}

.input-box input {
  border-radius: 5px;
  border: solid 1px #bbb;
  background-color: #fff;
  width: 100%;
  padding: 16px 10px;
}

.input-flex {
  display: flex;
}

.input-flex .css-17e8p2f-control {
  min-height: 49px;
  margin-right: 7px;
}

.input-box .react-datepicker-wrapper {
  width: 100%;
}

.signup-internal-cell,
.common-internal-cell {
  width: calc(50% - 20px / 2);
}

.signup-internal-cell:not(:first-child),
.common-internal-cell:not(:first-child) {
  margin-left: 20px;
}

.signup-form-cell .input-with-icon,
.common-form-cell .input-with-icon {
  max-width: 100%;
}

/* Datepicker */
.input-box .rc-time-picker {
  width: 100%;
}

.input-box .rc-time-picker input {
  height: 51px;
}

.input-box .rc-time-picker-clear {
  top: 12px;
  cursor: pointer;
}

.rc-time-picker-panel-input-wrap {
  height: 51px;
  display: flex;
  align-items: center;
}

.rc-time-picker-panel-input,
.rc-time-picker-input {
  font-size: 16px;
}

.input-box-select select {
  background-image: url(../../public/assets/images/icons/ArrowDown.svg);
  background-repeat: no-repeat;
  background-position: right 10px center;
  -webkit-appearance: none;
  border-radius: 5px;
  border: solid 1px #bbb;
  background-color: #fff;
  width: 100%;
  padding: 15px 10px;
  font-size: 14px;
}

.cellphone {
  position: relative;
  border-radius: 5px;
  border: solid 1px #bbb;
  background-color: #fff;
  width: 100%;
  padding-left: 90px;
}

.cellphone input {
  -webkit-appearance: none;
  border: 0;
  font-size: 16px;
}

.country-dropdown-container {
  position: absolute;
  left: 14px;
  top: 14px;
  cursor: pointer;
}

.flag-img {
  padding-right: 18px;
  position: relative;
}

.flag-img:after {
  content: "";
  position: absolute;
  right: 0;
  top: 9px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 6px 0 6px;
  border-color: #000000 transparent transparent transparent;
}

.country-code {
  margin-left: 5px;
}

.country-drop-down {
  display: none;
  position: absolute;
  left: -16px;
  top: 40px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 20px;
  min-width: 100px;
}

.country-drop-down.show {
  display: block;
}

.country-drop-down ul {
  margin: 0;
  padding: 0;
}

.country-drop-down ul li {
  list-style: none;
  margin: 0;
  padding: 10px 0;
  cursor: pointer;
}

.country-drop-down ul li img {
  margin-right: 5px;
  display: inline-block;
}

.signup-section .common-btn {
  font-size: 16px;
  font-weight: 500;
  margin-top: 20px;
  cursor: pointer;
}

.signup-form-cta .common-btn {
  width: 270px;
}

.signup-form-cta .common-btn:nth-child(2) {
  margin-left: auto;
}

.set-credentials {
  margin-bottom: 50px;
}

.single-set-credential {
  padding: 25px 30px 0;
  border-radius: 5px;
  border: solid 1px #bbb;
  background-color: #fff;
  width: calc(50% - 30px / 2);
  min-height: 510px;
}

.single-set-credential:not(:first-child) {
  margin-left: 30px;
}

.single-set-credential h4 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

.single-set-credential p {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}

.signup-info {
  font-size: 18px;
  font-weight: 500;
}

.signup-hints-text {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.67;
}

.validation-message {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.67;
}

.all-stages {
  justify-content: center;
}

.single-stage {
  width: calc(25% - 90px / 4);
  position: relative;
  margin-bottom: 30px;
  border: 1px solid #bbbbbb;
  border-radius: 5px;
}

.single-stage input[type="radio"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.single-stage:not(:first-child) {
  margin-left: 30px;
}

.single-stage label.cancer-stage {
  background-color: #ffffff;
  border-top: 5px solid #38519e;
  text-align: center;
  display: block;
  position: relative;
  height: 100%;
}

.stage-icon-container {
  background-color: #edf5ff;
  background-repeat: no-repeat;
  height: 100px;
  width: calc(100% + 2px);
  position: relative;
  left: -1px;
  border-radius: 0 0 20px 5px;
}

.stage-icon {
  height: 50px;
  width: 50px;
  background-position: top center;
  display: inline-block;
  overflow: hidden;
}

.stage-icon.stage1-icon {
  background-image: url(../../public/assets/images/stage1.svg);
}

.stage-icon.stage2-icon {
  background-image: url(../../public/assets/images/stage2.svg);
}

.stage-icon.stage3-icon {
  background-image: url(../../public/assets/images/stage3.svg);
}

.stage-icon.stage4-icon {
  background-image: url(../../public/assets/images/stage4.svg);
}

.stage-icon.donot-know {
  background-image: url(../../public/assets/images/donot-know.svg);
}

.stage-details {
  display: block;
  padding: 0 20px 20px 20px;
}

.stage-details strong {
  font-size: 24px;
  font-weight: 500;
  display: block;
}

.stage-content {
  display: block;
  font-size: 16px;
  font-weight: 500;
}

.selected-stage {
  background: #33ac2e url(../../public/assets/images/icons/Check-white.svg) no-repeat center center;
  background-size: 14px auto;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -12px;
  display: none;
}

.single-stage input[type="radio"]:checked+label.cancer-stage {
  background-color: #38519e;
  border-top-color: #33ac2e;
  color: #fff;
}

.single-stage input[type="radio"]:checked+label.cancer-stage .stage-icon-container {
  background-image: linear-gradient(to right, #394f9c 0%, #395ba4 16%, #3a83c1 73%, #3995cf 100%, #56bec0 100%);
}

.single-stage input[type="radio"]:checked+label.cancer-stage .stage-icon {
  background-position: center bottom;
}

.single-stage input[type="radio"]:checked+label.cancer-stage .selected-stage {
  display: block;
}

.single-stage:last-child .stage-details strong {
  padding: 30px 0 15px;
}

.where-u-diagnosed {
  border-radius: 5px;
  border: solid 1px #bbb;
  background-color: #fff;
  padding: 25px 30px 0;
}

.signup-secondry-heading {
  font-size: 20px;
  font-weight: 600;
}

.signup-form-row .common-btn {
  width: 100%;
  max-width: 270px;
}

.diagnosis-search-result thead th:first-child {
  padding-left: 45px;
}

.diagnosis-search-result thead th {
  font-size: 14px;
  font-weight: 700;
}

.diagnosis-search-result tbody td {
  font-size: 18px;
  font-weight: 500;
}

.sr-table-cell {
  padding: 10px 5px;
}

.sr-table-cell:nth-child(1) {
  width: 40%;
}

.sr-table-cell:nth-child(2) {
  width: 40%;
}

.sr-table-cell:nth-child(3) {
  width: 20%;
}

.sr-th .sr-table-cell:nth-child(1) {
  padding-left: 52px;
}

.sr-th {
  border-bottom: 2px solid #bbbbbb;
}

.sr-table-row:not(.sr-th) {
  border-bottom: 1px solid #bbb;
}

.sr-label {
  display: inline-block;
  margin-left: 15px;
}

.sr-table-cell {
  font-size: 18px;
}

.sr-table-cell .custom-radio {
  min-width: 32px;
}

.sr-th .sr-table-cell {
  font-size: 14px;
  font-weight: 700;
}

.multi-opt.yes-opt-selected>div:not(:first-child) {
  margin-left: 0;
}

.multi-opt.yes-opt-selected>div:nth-child(2n) {
  margin-left: 15px;
}

.multi-opt.yes-opt-selected>div {
  margin-bottom: 10px;
  margin-top: 10px;
  min-width: 48%;
}

#treatment-other-specification {
  margin-left: 0;
}

.thanks-text {
  color: #33ac2e;
  font-size: 48px;
  font-weight: 500;
}

.check-inbox {
  color: #38519e;
  font-size: 24px;
  font-weight: 700;
}

.check-inbox img {
  display: block;
  margin: 0 auto;
  width: 50px;
  height: auto;
}

.paid-membership-block {
  border: 1px solid #bbbbbb;
  border-radius: 5px;
  padding: 20px 30px 30px;
  position: relative;
  margin-top: 120px;
}

.paid-membership-block h3 {
  color: #38519e;
  font-size: 24px;
  font-weight: 500;
  max-width: 80%;
}

.paid-membership-block h4 {
  font-size: 20px;
  font-weight: 600;
}

.paid-membership-features {
  margin: 0;
  padding: 0;
}

.paid-membership-features li {
  list-style: none;
  padding: 7px 0;
}

.paid-membership-features li:not(:first-child) {
  border-top: 1px solid #bbb;
  font-size: 11px;
}

.paid-membership-features .common-btn {
  width: 270px;
}

.paid-membership-price-block {
  border-radius: 10px;
  margin-left: 20px;
  margin-top: 33px;
  background-image: linear-gradient(to right, #56bec0 0%, #3995cf 0%, #3a83c1 27%, #395ba4 84%, #394f9c 100%);
  text-align: center;
  font-size: 48px;
  font-weight: 700;
  color: #fff;
  padding: 20px;
  max-width: 135px;
}

.paid-membership-price-block sup {
  font-size: 14px;
  font-weight: 500;
  position: relative;
  top: -5px;
}

.paid-membership-price-block .mm {
  font-size: 12px;
  font-weight: 500;
  display: block;
}

.paid-membership-img {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 312px;
}

/*After Logedin header*/
#header.logedin-header {
  padding: 20px 0;
  border-bottom: 1px solid #bbb;
}

#header.logedin-header .logo img {
  max-width: 325px;
}

#header.logedin-header:before {
  background-color: #fff;
  top: 0;
  opacity: 0.9;
}

.header-profile-icon {
  background-image: linear-gradient(to right, #56bec0 0%, #3995cf 0%, #3a83c1 27%, #395ba4 84%, #394f9c 100%);
  color: #fff;
  text-align: center;
  padding: 6px 0 0 0;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  position: relative;
  border: 2px solid #d1edff;
}

.user-name-intial {
  display: block;
  cursor: pointer;
}

/*.header-profile-icon:before{ content: "";background-image: linear-gradient(to right, #56bec0 0%, #3995cf 0%, #3a83c1 27%, #395ba4 84%, #394f9c 100%); color:#fff;text-align:center; height: 36px; width: 36px; border-radius: 50%; position: absolute; left: -2px; top: -2px; opacity: .5; z-index: -1;}*/
.header-notification {
  margin-right: 40px;
}

.header-notification:after {
  background-color: #414042;
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -8px;
  right: -20px;
  height: 16px;
  width: 1px;
}

.have-notification {
  border: solid 2px #fff;
  border-radius: 50%;
  background-image: linear-gradient(to right, #56bec0 0%, #3995cf 0%, #3a83c1 27%, #395ba4 84%, #394f9c 100%);
  height: 12px;
  width: 12px;
  top: 1px;
  right: 1px;
}

.notification-dropdown {
  width: 280px;
  top: 40px;
  right: -11px;
}

.S-drop-down.notification-dropdown .dropDown-body {
  padding: 5px 0;
}

.topNotificationList {
  width: 100%;
}

.topNotificationList ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.topNotificationList ul li {
  border-bottom: 1px solid #bbbbbb;
  padding: 20px 16px;
}

.topNotificationList ul li:last-child {
  border-bottom: 0;
}

.topNotificationList ul li a {
  text-decoration: none;
}

.topNotificationList ul li a:hover {
  text-decoration: none;
}

.topNotificationListIcon {
  min-width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #f5f7fb;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  position: relative;
}

.topNotificationListIcon img {
  max-width: 16px;
}

.unreadIcon:before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-image: linear-gradient(to right, #56bec0 0%, #3995cf 0%, #3a83c1 27%, #395ba4 84%, #394f9c 100%);
  border: 2px solid #d1edff;
  position: absolute;
  right: -2px;
  top: -2px;
}

.topNotificationContent strong {
  font-size: 12px;
  font-weight: 700;
  color: #414042;
  display: block;
  text-transform: uppercase;
}

.topNotificationContent p {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.43;
  color: #000000;
  margin-bottom: 5px;
}

.receivedTime {
  font-size: 12px;
  color: #8798ad;
}

/*Profile*/
.User-profile {
  margin-bottom: 50px;
}

.profile-userName-symbol {
  background-image: linear-gradient(to right, #56bec0 0%, #3995cf 0%, #3a83c1 27%, #395ba4 84%, #394f9c 100%);
  color: #fff;
  text-align: center;
  padding: 6px 0 0 0;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  position: relative;
  border: 2px solid #d1edff;
}

/*.profile-userName-symbol:before{ content: "";background-image: linear-gradient(to right, #56bec0 0%, #3995cf 0%, #3a83c1 27%, #395ba4 84%, #394f9c 100%); color:#fff;text-align:center; height: 36px; width: 36px; border-radius: 50%; position: absolute; left: -2px; top: -2px; opacity: .5; z-index: -1;}*/
.profile-UserName {
  color: #000;
  font-size: 12px;
  font-weight: 500;
  margin-left: 10px;
}

.profile-dropDown {
  width: 280px;
  right: -5px;
  top: 45px;
  text-align: left;
}

.profile-links {
  margin: 5px 0;
}

.profile-links li {
  list-style: none;
  padding: 5px 0;
  margin: 0;
}

.profile-links li a,
.S-drop-down .dropDown-footer a {
  color: #38519e;
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  text-decoration: none;
}

.profile-links li a:hover,
.S-drop-down .dropDown-footer a:hover {
  text-decoration: underline;
}

.after-login-heading {
  font-size: 24px;
  font-weight: 700;
}

.profile-call {
  width: calc(50% - 30px / 2);
}

.profile-card {
  border-radius: 5px;
  border: solid 1px #bbb;
  background-color: #fff;
  padding: 30px;
  position: relative;
}

.profile-card-title {
  font-size: 20px;
  font-weight: 600;
  padding-right: 60px;
}

.edit-profile {
  color: #38519e;
  font-size: 16px;
  font-weight: 600;
  position: absolute;
  right: 30px;
  top: 30px;
  text-transform: uppercase;
}

.edit-icon {
  background: url(../../public/assets/images/icons/Edit-icon.svg) no-repeat left center;
  padding-left: 20px;
  cursor: pointer;
}

.persona-info-read-only .personal-info-form-row:not(:last-child) {
  margin-bottom: 35.25px;
}

.persona-info-read-only.edit-mode .personal-info-form-row:not(:last-child) {
  margin-bottom: 10px;
}

.persona-info .input-box {
  width: 100%;
}

.personal-info-form-row label,
.my-plan-row label,
.profile-account-login-row label {
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  max-width: 130px;
  margin-right: 30px;
  display: inline-block;
}

.pi-crt-value,
.my-plan-crt-value,
.profile-account-login-value {
  font-size: 18px;
  font-weight: 500;
  display: block;
}

.my-plan-crt-value,
.profile-account-login-value {
  margin-bottom: 0;
}

.personal-info-form-row .profile-cta {
  padding-left: 160px;
  min-height: 38px;
}

.profile-cta .common-btn {
  min-width: 150px;
  padding-left: 35px;
  padding-right: 35px;
  font-size: 16px;
  font-weight: 600;
}

.my-plan-row,
.profile-account-login-row {
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #bbb;
}

.profile-account-login-row:last-child {
  border-bottom: 0;
}

.verify-code-block>p {
  font-size: 14px;
  font-weight: 500;
}

.verify-code-block label {
  color: #000;
}

.ctype-value,
.cstage-value {
  color: #414042;
  min-height: 103px;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
}

.profile-card.ctype .profile-card-title {
  padding-right: 0;
}

.edit-cstage {
  color: #38519e;
  font-weight: 600;
  right: 30px;
  top: 30px;
  text-transform: uppercase;
}

.forgot-nickname {
  color: #38519e;
  font-size: 14px;
  font-weight: 500;
}

.cstage .input-box-select {
  width: 100%;
}

.comming-soon-class {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

/*Our Team*/
.team-list {
  list-style: none;
}

.team-list>li {
  background-color: #dddee3;
  border: 1px solid #bcbaba;
  border-radius: 20px;
  padding-bottom: 3px;
  width: calc(25% - 120px / 4);
  margin-right: 40px;
  margin-bottom: 40px;
}

.team-list>li:nth-child(4n) {
  margin-right: 0;
}

.team-member-pic {
  border-radius: 20px 20px 0 0;
  overflow: hidden;
  height: 280px;
  cursor: pointer;
}

.team-member-readMore {
  padding-top: 5px;
}

.team-content {
  background-color: #fffdfd;
  padding: 10px;
  border-radius: 3px 3px 20px 20px;
  height: 110px;
}

.team-content h3 {
  color: #3e4552;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  margin-bottom: 0;
  cursor: pointer;
}

.team-content p {
  color: #3e4552;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  margin-bottom: 0;
}

.team-content a {
  color: #3d92db;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

/*Our team details*/
.team-detail-modal .s-modal-box {
  max-width: 1170px;
}

.s-modal-header .close-s-modal-icon {
  /* background: url("../../public/assets/images/icons/close-icon.svg") no-repeat 0 0; */
  height: 20px;
  width: 20px;
  float: right;
  cursor: pointer;
  background-size: contain;
}

.team-member-detail-pic {
  background-color: #9dcdeb;
  height: 320px;
  flex: 0 0 320px;
  border-radius: 50%;
  overflow: hidden;
}

.team-member-detail-pic img {
  width: 100%;
  height: 100%;
}

.team-member-detail-content {
  padding-left: 30px;
}

.team-member-detail-content h5 {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 10px;
}

.team-member-detail-content h6 {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.team-member-detail-content h6:after {
  background-color: #3e4552;
  content: "";
  height: 1px;
  width: 355px;
  left: 0;
  bottom: 0;
  position: absolute;
}

.team-member-detail-content p {
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
}

.modal {
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  overflow-y: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 9;
}

/* Modal content */
.modal-content {
  background-color: #fff;
  border-radius: 5px;
  margin: 12% auto 0 auto;
  width: 100%;
  margin-bottom: 50px;
  max-width: 1170px;
}

.modal-flex {
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 30px 30px 30px;
  width: 100%;
}

.modal-info {
  background-color: #888;
  border: 1px solid #888;
}

.s-modal-header {
  border: none;
  padding: 15px;
  position: relative;
}

/*FAQ*/

.faq-question {
  border-bottom: 1px solid #bbb;
  padding: 15px 0;
  cursor: pointer;
}

.faq-question h4 {
  color: #38519e;
  font-size: 24px;
  font-weight: 500;
  cursor: pointer;
}

.faq-ans {
  height: 0;
  overflow: hidden;
}

.faq-ans.open {
  height: auto;
  padding-bottom: 20px;
  padding-top: 20px;
}

.faq-ans p {
  font-size: 16px;
  line-height: 26px;
  font-size: 400;
  margin-bottom: 15px;
}

.faq-que-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.accordion-arrow {
  height: 12px;
  width: 12px;
}

/* About Us */
.page-content-wrapper {
  padding-top: 50px;
}

.section-sub-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #5b5f68;
  margin-bottom: 30px;
}

.hero-image img {
  border-radius: 20px;
}

.about-page-content {
  margin: 30px 0;
}

.about-page-content p {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #5b5f68;
}

section .about-page-content ol,
section .about-page-content ul {
  font-size: 14px;
}

.leadership-list {
  display: flex;
  list-style: none;
  justify-content: space-between;
  margin: 0 !important;
  gap: 30px;
}

.leadership-list li {
  width: 30%;
}

.leadership-team-section .section-heading {
  margin-bottom: 50px;
}

.about-us-content-wrapper {
  background: #edf5ff;
  padding-top: 50px;
  position: relative;
  margin-bottom: 150px;
}

.about-us-content-wrapper::after {
  content: "";
  background-image: url("/public/assets/images/team-divider.svg");
  background-size: 100% auto;
  min-height: 126px;
  position: absolute;
  width: 100%;
  background-repeat: no-repeat;
}

.about-team-content {
  background: #ffffff;
  border: 1px solid #d8d9da;
  border-radius: 0 0 20px 20px;
  padding: 15px 10px;
}

.about-team-content h3 {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #414042;
}

.about-team-content p {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #5b5f68;
}

.about-us-board {
  background-image: url("/public/assets/images/board-bg.svg");
  padding-top: 50px;
  background-size: cover;
}

.about-us-board .section-heading {
  margin-bottom: 30px;
}

.board-image img {
  width: 210px;
  border-radius: 50%;
}

.board-list {
  margin: auto;
  display: flex;
  width: 80%;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.board-card {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}

.board-name {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-top: 30px;
  color: #5b5f68;
}

/* Team-science */
.science-content-left,
.science-content-right {
  background: #ebf0f4;
  border-radius: 40px 0;
  padding: 50px 185px 50px 50px;
  max-width: 780px;
}

.science-content-right {
  padding: 50px 50px 50px 185px;
}

.science-data-card p {
  font-size: 14px;
  line-height: 25px;
  color: #414042;
  margin-bottom: 20px;
}

.science-data-card {
  position: relative;
  margin-top: 70px;
}

.science-image-right {
  position: absolute;
  top: 27px;
  right: 0;
  width: 530px;
}

.science-image-left {
  width: 530px;
}

.science-image-right img,
.science-image-left img {
  border-radius: 15px;
}

.science-content-right {
  position: absolute;
  top: -28px;
  right: 0;
  z-index: -1;
}

.ts-short-profile {
  padding-bottom: 20px;
  border-bottom: 1px solid #bbb;
}

.ts-short-profile-pic {
  height: 100px;
  width: 100px;
  border-radius: 8px;
  overflow: hidden;
  /* border: 0.5px solid #ccc; */
}

.ts-short-profile-content {
  margin-left: 20px;
}

.ts-short-profile-content h5,
.ts-profile-title {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.ts-profile-qf-list {
  border-bottom: 1px solid #bbb;
}

.ts-profile-qf-list li {
  margin-bottom: 5px;
  list-style-position: inside;
  font-size: 12px;
  font-weight: 500;
}

.launch-soon {
  font-size: 34px;
  font-weight: 700;
  line-height: 1.26;
  text-indent: -4px;
  margin: 50px 0;
  color: #354f97;
  text-transform: uppercase;
}

.header-label {
  margin: 0 !important;
}

.footer-label {
  margin: 120px 20px;
}

.removelink {
  cursor: default;
}

.input-box textarea {
  padding: 15px;
}

.section-wrapper {
  display: flex;
  background: #b7d2e5;
  margin-bottom: 100px;
}

.content-wrapper {
  width: 50%;
  padding: 30px 40px 30px 50px;
}

.image-wrapper {
  width: 50%;
}

.image-wrapper img {
  float: right;
  border-top-left-radius: 121px 70%;
  border-bottom-left-radius: 121px 66%;
}

.title-wrapper h3 {
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
}

.message-wrapper p {
  font-size: 21px;
  padding-top: 30px;
  font-weight: 600;
}

.session-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 100px;
  margin-bottom: 20px;
}

.feature-description {
  width: 60%;
  margin: 30px 0;
  font-weight: 500;
}

.footer-bottom-links {
  display: flex;
  align-items: center;
}

#ceo-message-section {
  width: 100%;
  background: #b7d2e5;
  display: flex;
  overflow: hidden;
  color: #45474d;
}

#ceo-message-section .content_box {
  width: 50%;
  display: flex;
  justify-content: right;
  align-items: center;
}

#ceo-message-section .content_box .content {
  max-width: 500px;
  padding: 25px;
}

#ceo-message-section .content_box h3 {
  font-size: 48px;
  font-weight: 700;
  line-height: 53px;
  padding-bottom: 25px;
  margin: 0;
}

#ceo-message-section .content_box p {
  font-size: 21px;
  font-weight: 600;
  padding: 0 0 25px;
  margin: 0;
}

#ceo-message-section .image_box {
  width: 50%;
  position: relative;
  overflow: hidden;
}

#ceo-message-section .image_box:before {
  content: "";
  background: url("../../public/assets/images/img_frame.png") no-repeat 0 0;
  width: 100px;
  height: 100%;
  background-size: 100% 100%;
  position: absolute;
  left: -1px;
  top: 0;
}

#ceo-message-section .image_box img {
  width: 100%;
  height: auto;
  max-height: 600px;
  object-fit: cover;
  display: block;
}

p.slider_bottom_text {
  display: flex;
  justify-content: flex-end;
  font-weight: 600;
  font-size: 13px;
}

.slider_bottom_text {
  margin-right: 50px;
}

.slider_bottom_text a {
  margin-left: 3px;
}

.beta-reg-form .container {
  max-width: 780px;
  margin: 0 auto;
  padding: 30px 15px 0;
}

.beta-reg-form-container {
  width: 100%;
  padding: 15px 0 0 0;
}

.beta-reg-form .container p,
.beta-reg-form .container h1 {
  text-align: center;
}

.custom-checkbox label {
  padding-left: 5px;
}

.beta-reg-form .common-btn {
  margin-top: 20px;
}

.input-box {
  position: relative;
}

.input-box svg {
  position: absolute;
  top: 15px;
  right: 15px;
}

.captcha-input {
  margin-left: 15px;
}

.captcha-error {
  margin-left: 15px;
}

/**************************/
/******Media Query*********/
/**************************/

@media (min-width: 1400px) {
  .container-fluit {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (max-width: 1400px) {
  .home-banner {
    background-position: center 60px !important;

  }
}

@media (min-width: 1200px) {
  #header .logo {
    width: 250px;
  }

  #header.headerBg .logo img {
    max-width: 220px;
  }
}

@media (max-width: 1199px) {

  /*Header*/
  .header-wraper {
    flex-direction: row-reverse;
  }

  #header .logo,
  #header.logedin-header .logo img {
    width: 250px;
    order: 1;
    margin-right: auto;
  }

  .top-menu ul li:not(:first-child) {
    margin-left: 10px;
  }

  .top-menu ul li a,
  .header-right-links button {
    font-size: 15px;
  }

  /*main Right Container*/
  .dashboard-right-container {
    margin-left: 0;
    width: 100%;
  }

  .sidebar-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    top: 0;
    left: 0;
    display: none;
    z-index: 100;
  }

  .sidebar-overlay.show {
    display: block;
  }

  /*Home*/
  .container {
    max-width: 92%;
  }

  .count-section {
    max-width: 90%;
  }

  .count-section ul li strong {
    font-size: 22px;
  }

  .banner-content-box p {
    font-size: 14px;
  }

  .banner-content-box {
    margin-left: 15px;
  }

  .count-section ul li>div>span {
    max-width: 75%;
    display: block;
    margin: 0 auto;
  }

  .home-our-tele-scientists:before {
    top: -60px;
  }

  .what-is-telescience {
    padding: 50px 0 0px;
  }

  .home-services {
    padding-top: 0;
  }

  .home-inspiring-stories,
  .home-consult-scienctists {
    padding: 50px 0;
  }

  .home-Partnership {
    padding: 50px 0 20px 0;
  }

  .telescientists-tab-section ul li.active-tab {
    background: none;
  }

  .inspiring-story-section {
    padding: 20px;
  }

  .inspiring-story-wrapper {
    max-width: 46%;
  }

  .why-synaptical {
    padding-top: 80px;
  }

  .why-synaptical .section-sub-heading br {
    display: none;
  }

  .home-consult-scienctists {
    min-height: 40vh;
  }

  .telescientists-tab-section {
    max-width: 100%;
    position: relative;
  }

  .telescientists-dropdown {
    display: block;
  }

  .telescientists-dropdown:before {
    content: "";
    background: url(../../public/assets/images/icons/ArrowDown-white.svg) no-repeat center right 10px;
    height: 100%;
    width: 24px;
    display: block;
    position: absolute;
    right: 10px;
    top: 0;
  }

  #telescientists-dropdown-opt {
    display: none;
  }

  #telescientists-dropdown-opt.show {
    display: block;
  }

  .telescientists-tab-with-slider {
    flex-direction: column;
    padding-top: 10px;
  }

  .home-tele-scientists-slider .swiper {
    padding-bottom: 60px;
  }

  .ht-cost-body ul li {
    margin-bottom: 5px;
  }

  .footer {
    padding-top: 50px;
  }

  .footer-logo img {
    max-width: 250px;
  }

  .footer-left-container {
    width: 300px;
  }

  .footer-media li:not(:first-child) {
    margin-left: 20px;
  }

  .footer-menu li {
    min-width: 23%;
  }

  /*Sign Up*/
  body.login {
    padding-top: 140px;
  }

  .signup-features-list {
    min-height: 380px;
  }

  .signup-top-steps {
    top: 115px;
  }

  .signup-step-count {
    left: -50px;
  }

  /*Profile*/
  .my-plan .profile-cta {
    flex-direction: column;
    padding-top: 30px;
  }

  .my-plan .profile-cta .common-btn:nth-child(2) {
    margin: 20px 0 0 0;
  }

  .ctype-and-cstage {
    flex-direction: column;
  }

  .ctype-and-cstage .profile-call {
    width: 100%;
  }

  .ctype-and-cstage .profile-call:nth-child(2) {
    margin: 30px 0 0 0;
  }

  .science-data-card:nth-child(1) .science-content-left,
  .science-data-card:nth-child(2) .science-content-right {
    max-width: 670px;
  }
}

@media (max-width: 1023px) {

  /*Header*/
  .header-menu-trigger {
    width: 30px;
    height: 20px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    display: block;
    z-index: 9;
  }

  .header-menu-trigger span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #394f9c;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }

  .header-menu-trigger span:nth-child(1) {
    top: 0px;
  }

  .header-menu-trigger span:nth-child(2) {
    top: 8px;
  }

  .header-menu-trigger span:nth-child(3) {
    top: 16px;
  }

  .header-menu-trigger.menu-open span {
    background-color: #fff;
  }

  .header-menu-trigger.menu-open span:nth-child(1) {
    top: 8px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  .header-menu-trigger.menu-open span:nth-child(2) {
    opacity: 0;
    left: -60px;
  }

  .header-menu-trigger.menu-open span:nth-child(3) {
    top: 8px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }

  .header-right {
    background-image: linear-gradient(to right, #56bec0 0%, #449dd0 100%);
    width: 100vw;
    height: 100vh;
    right: -110vw;
    top: 0;
    position: fixed;
    overflow-y: auto;
    z-index: 8;
    flex-direction: column;
    transition: all 0.5s ease-out;
    padding: 70px 40px 20px 40px;
  }

  .header-right.menu-open {
    right: 0;
    transition: all 0.5s ease-in;
  }

  .header-right-label {
    font-size: 18px;
    color: #000;
    font-weight: 500;
  }

  .top-menu {
    width: 100%;
  }

  .top-menu ul {
    flex-direction: column;
  }

  .top-menu>ul>li {
    margin-top: 30px;
    text-align: center;
  }

  .top-menu ul li a,
  .header-right-links button {
    font-size: 14px;
  }

  .top-menu ul li:not(:first-child) {
    margin-left: 15px;
  }

  .top-menu>ul>li>a {
    color: #fff;
    display: inline-block;
    font-size: 32px;
    font-weight: 700;
  }

  .top-menu ul li.has-submenu a {
    background: url(../../public/assets/images/icons/ArrowDown-white.svg) no-repeat right 16px;
    background-size: 28px auto;
    padding-right: 42px;
  }

  .signin-signup-container {
    margin: auto 0 0 0;
  }

  .signin-signup-container .header-search {
    display: none;
  }

  .signin-signup-container button {
    color: #fff;
  }

  .signin-signup-container {
    width: 100%;
    justify-content: center;
  }

  .header-right-links {
    width: 100%;
    text-align: center;
  }

  .header-right-links button {
    display: block;
    width: 100%;
    margin-left: 0;
  }

  .header-right-links button.sign-up-btn {
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    max-width: 300px;
  }

  .header-search.mobile-search {
    display: block;
    margin-right: 20px;
    border: 0;
  }

  body.login .header-search.mobile-search,
  body.login .header-menu-trigger,
  body.login .header-right-label {
    display: none;
  }

  body.login .header-right {
    position: static;
    background: transparent;
    height: auto;
    padding: 0;
    overflow: visible;
  }

  body.login .header-right-links {
    text-align: right;
    width: auto;
  }

  body.login .header-right-links button.sign-up-btn {
    margin: 0;
  }

  body.login .signin-signup-container {
    justify-content: flex-end;
  }

  .ts-list li {
    width: calc(50% - 30px / 2);
  }

  .ts-list li:nth-child(3n) {
    margin-right: 30px;
  }

  .ts-list li:nth-child(2n) {
    margin-right: 0;
  }

  /*Home*/
  .section-heading {
    font-size: 30px;
  }

  .home-banner {
    min-height: 75vh;
    background-position: center 60px !important;
  }

  .home-how-it-work .container {
    background-size: 50%;
  }

  .count-section ul li>div>span {
    max-width: 93%;
  }

  .how-it-work-content {
    max-width: 60%;
    position: relative;
    z-index: 2;
  }

  .home-how-it-work-props {
    width: 50%;
    background-size: 100% auto !important;
  }

  .why-synaptical-img1 {
    width: 48%;
  }

  .what-is-telescience-row.row1 .telescience-img-box,
  .what-is-telescience-row.row2 .telescience-img-box {
    max-width: 400px;
  }

  .what-is-telescience-row.row1 .what-is-telescience-content,
  .what-is-telescience-row.row2 .what-is-telescience-content {
    max-width: 400px;
    padding: 50px 20px;
    top: 35px;
  }

  .what-is-telescience-row.row2 .what-is-telescience-content {
    border-radius: 10px 50px 10px 10px;
  }

  .what-is-telescience-row.row1 .what-is-telescience-content {
    border-radius: 50px 10px 10px 10px;
  }

  .what-is-telescience-row.row1 {
    margin-top: 30px;
    padding-bottom: 92px;
  }

  .home-consult-scienctists h4 {
    font-size: 36px;
  }

  .home-tele-scientists-pic {
    width: 100%;
  }

  .hts-left {
    margin-left: auto;
    max-width: 300px;
    margin-right: 10px;
  }

  .hts-right {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }

  .ht-cost-container {
    flex-direction: column;
    align-items: center;
  }

  .ht-cost-pack {
    width: 60%;
  }

  .ht-cost-pack:not(:first-child) {
    margin: 30px 0 0 0;
  }

  /*Footer*/
  .footer-container {
    flex-direction: column;
  }

  .footer-left-container {
    width: 100%;
  }

  .footer-right-container {
    width: 100%;
    margin: 60px 0 0 0;
    padding-left: 0;
  }

  .footer-media {
    margin-top: 50px;
  }

  .footer-bottom {
    flex-direction: column-reverse;
  }

  .footer-bottom-links {
    margin-left: 0;
    margin-bottom: 20px;
  }

  .footer-bottom-links ul li:first-child a {
    padding-left: 0;
  }

  .footer-bottom-links ul {
    justify-content: center;
  }

  .footer-media li:not(:first-child) {
    margin-left: 30px;
  }

  .copyright {
    text-align: center;
  }

  /*Sign Up*/
  body.login {
    padding-top: 116px;
  }

  .signup-plan {
    flex-direction: column;
  }

  .sign-up-single-plan {
    max-width: 100%;
  }

  .sign-up-single-plan:nth-child(2) {
    margin-top: 30px;
    margin-left: 0;
  }

  .signup-form-cell label,
  .common-form-cell label {
    position: relative;
  }

  .signup-form-cell label .opt-field,
  .common-form-cell label .opt-field {
    position: absolute;
    top: 2px;
    margin-left: 5px;
  }

  .signup-top-steps:before {
    content: "";
    position: absolute;
    left: calc((-100vw + 100%) / 2);
    width: 100vw;
    background: #fff;
    height: 100%;
    z-index: -1;
    top: 0;
  }

  .signup-step-count {
    display: none;
  }

  .single-stage {
    width: calc(33.33% - 60px / 3);
  }

  .single-stage:nth-child(4n) {
    margin-left: 0;
  }

  .thanks-text {
    font-size: 36px;
  }

  .paid-membership-block {
    padding: 20px 25px 25px;
    margin-top: 40px;
  }

  .paid-membership-block h3 {
    max-width: 100%;
  }

  .paid-membership-img {
    display: none;
  }

  /*Profile*/
  .profile-row {
    flex-direction: column;
  }

  .User-profile {
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
  }

  .profile-call {
    width: 100%;
  }

  .profile-call:nth-child(2) {
    margin-left: 0;
    margin-top: 30px;
  }

  /* About Us */
  .about-team-content p {
    text-align: center;
    font-size: 12px;
  }

  .about-team-content h3 {
    font-size: 13px;
  }

  .about-us-content-wrapper {
    margin-bottom: 100px;
  }

  .board-list {
    width: 100%;
  }

  .board-image img {
    width: 200px;
  }

  .science-data-card {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    margin-top: 0;
  }

  .science-content-left,
  .science-content-right {
    max-width: 57%;
  }

  .science-data-card:nth-child(2) .science-content-right {
    background: #ebf0f4;
    border-radius: 40px 0;
    padding: 50px 50px 50px 185px;
    position: static;
    margin-left: auto;
  }

  .science-data-card:nth-child(2) .science-image-left {
    position: absolute;
    max-width: 90%;
    top: 80px;
  }

  .science-data-card:nth-child(1) .science-image-right {
    top: 80px;
    position: relative;
    max-width: 90%;
  }

  .science-data-card:nth-child(1) .science-content-right {
    position: absolute;
    top: -28px;
    right: 0;
    z-index: -1;
  }

  #ceo-message-section {
    display: block;
  }

  #ceo-message-section .content_box,
  #ceo-message-section .image_box {
    width: 100%;
  }

  #ceo-message-section .content_box .content {
    max-width: 100%;
    padding: 25px;
  }

  #ceo-message-section .image_box:before {
    display: none;
  }

  #ceo-message-section .content_box h3 {
    font-size: 38px;
    line-height: 43px;
  }

  #ceo-message-section .content_box p {
    font-size: 18px;
    line-height: 26px;
  }

  .feature-description {
    width: 100%;
  }

  .slider_bottom_text {
    margin-right: 30px;
    margin-top: 20px;
  }
}

@media (max-width: 991px) and (orientation: landscape) {

  /*Home*/
  .why-synaptical-img1 {
    width: 43%;
  }
}

@media (max-width: 991px) {

  /*Our Team*/
  .team-list>li {
    width: calc(50% - 40px / 2);
    margin-right: 20px;
  }

  .team-list>li:nth-child(2n) {
    margin-right: 0;
  }

  .team-member-detail-content {
    padding: 0;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }

  .team-member-detail-content h6:after {
    width: 100%;
  }

  .team-member-detail-content p {
    text-align: center;
  }

  .modal-content {
    width: 100% !important;
    margin-top: 140px;
  }

  .team-member-detail-pic {
    width: 250px;
    flex: 0 0 250px;
  }

  .team-member-detail-box {
    flex-direction: column;
    align-items: center;
  }

  .synaptical-section {
    flex-direction: column;
  }

  .why-synaptical .section-sub-heading {
    max-width: 100%;
  }

  .inspiring-story-wrapper {
    max-width: 100%;
  }

  .inspiring-story-section {
    margin-top: 30px;
  }

  .what-is-telescience-row.row1 {
    padding-bottom: 75px;
  }

  footer {
    padding: 30px 0 0;
  }

  .science-content-left {
    max-width: 100%;
  }

  .science-data-card:nth-child(2) .science-content-right,
  .science-data-card:nth-child(1) .science-content-left {
    max-width: 100%;
    padding: 120px 30px 20px 30px;
  }

  .science-data-card:nth-child(2) .science-image-left {
    position: relative;
  }
}

@media (max-width: 767px) {

  section ol,
  section ul {
    font-size: 16px;
  }

  .no-flex {
    display: block;
  }

  .full-width {
    width: calc(100% - 0px) !important;
  }

  .no-flex .common-form-cell {
    width: calc(100% - 0px);
    margin-bottom: 20px;
  }


  .container {
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .header-space {
    margin-top: 101px;
  }


  /*Header*/
  /*.header-right{ padding: 20px;}*/
  .top-menu>ul>li {
    margin-top: 20px;
    margin-left: 0 !important;
  }

  .top-menu>ul>li>a {
    font-size: 25px;
  }

  .top-menu ul li.has-submenu a {
    background: url(../../public/assets/images/icons/ArrowDown-white.svg) no-repeat right 14px;
    background-size: 18px auto;
    padding-right: 35px;
  }

  .header-search-input input[type="text"] {
    font-size: 24px;
  }

  /*Contact Us*/
  .contact-us-container,
  .contact-form-cta {
    flex-direction: column;
  }

  .contact-form-container,
  .contact-us-address,
  .contact-form-cta {
    width: 100%;
  }

  .contact-us-address,
  .contact-form-cta .common-btn:nth-child(2) {
    margin-top: 30px;
    margin-left: 0;
    margin-right: 0;
  }

  /*Home*/
  .video-player-container {
    display: flex;
    background-color: #00000087;
  }

  .banner-content-box {
    padding-top: 150px;
  }

  .count-section ul li strong {
    font-size: 16px;
    line-height: 18px;
  }

  .count-section ul>li>div>span {
    font-size: 12px;
  }

  .banner-content-box {
    max-width: 430px;
  }

  .home-banner {
    background-position: center;
    min-height: 150vh;
    height: auto;
  }

  .banner-content-box h1 {
    font-size: 22px;
    padding-left: 0;
  }

  .banner-cta {
    text-align: left;
  }

  .home-how-it-work .container {
    padding-top: 50px;
  }

  .home-how-it-work-cta .common-btn {
    padding: 15px 30px;
  }

  .why-synaptical-row {
    flex-direction: column;
  }

  .why-synaptical-col.col2 {
    margin-left: 0;
  }

  .why-synaptical .section-sub-heading {
    max-width: 100%;
  }

  .why-synaptical-img1 {
    position: static;
    width: 100%;
  }

  .home-consult-scienctists .container {
    max-width: 100%;
  }

  .home-how-it-work-props {
    width: 50%;
    background-size: 100% auto;
  }

  .home-services {
    padding-top: 0;
  }

  .home-consult-scienctists::after {
    display: none;
  }

  .home-consult-bg-image {
    display: none;
  }

  .home-our-tele-scientists:before {
    top: -50px;
  }

  .what-is-telescience-row.row2 {
    display: flex;
    flex-direction: column-reverse;
  }

  .HT-cost {
    padding: 50px 0;
  }

  .ht-cost-pack {
    width: 70%;
  }

  .ht-cost-note {
    font-size: 16px;
  }

  /*Sign Up*/
  .signup-features-list {
    min-height: inherit;
    margin-bottom: 20px;
  }

  .set-credentials {
    flex-direction: column;
    align-items: center;
  }

  .single-set-credential {
    width: 100%;
    max-width: 370px;
    margin-left: 0;
    margin-right: 0;
  }

  .single-set-credential:not(:first-child) {
    margin-top: 30px;
    margin-left: 0;
  }

  .single-stage {
    width: calc(50% - 30px / 2);
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .single-stage:nth-child(2n) {
    margin-left: 30px !important;
  }

  .stage-content {
    min-height: 100px;
  }

  .sr-table {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #bbb;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  }

  .sr-table-row {
    flex-direction: column;
    padding: 10px 0;
    margin-left: 20px;
    margin-right: 20px;
  }

  .sr-table-row.sr-th {
    display: none;
  }

  .sr-table-row:last-child {
    border-bottom: 0;
  }

  .sr-table-cell {
    padding: 5px 0;
    font-size: 16px;
  }

  .sr-table-cell:nth-child(1),
  .sr-table-cell:nth-child(2),
  .sr-table-cell:nth-child(3) {
    width: 100%;
  }

  .sr-label {
    margin-left: 5px;
    margin-top: 5px;
  }

  .paid-membership-block h3 {
    font-size: 22px;
  }

  .paid-membership-block h4 {
    font-size: 18px;
  }

  /*Profile*/
  .profile-card {
    padding: 15px;
  }

  /*Our Team*/
  .team-list>li {
    width: calc(50% - 40px / 2);
    margin-right: 40px;
  }

  .team-list>li:nth-child(3n),
  .team-list>li:nth-child(4n) {
    margin-right: 40px;
  }

  .team-list>li:nth-child(2n) {
    margin-right: 0;
  }

  .team-member-detail-box {
    flex-direction: column;
    align-items: center;
  }

  .team-member-detail-pic {
    width: 250px;
    flex: 0 0 250px;
  }

  .modal-flex {
    width: 100%;
  }

  .team-member-detail-content {
    padding: 0;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }

  .team-member-detail-content h6:after {
    width: 100%;
  }

  .team-member-detail-content p {
    text-align: center;
  }

  /* Modal */
  .modal-content {
    margin-top: 140px;
  }

  .modal-content {
    width: 100% !important;
  }

  /* INspiring Story */
  .inspiring-story-wrapper {
    max-width: 100%;
  }

  .inspiring-story-section {
    margin-top: 30px;
  }

  .what-is-telescience-row .telescience-img-box {
    margin-top: 25px;
  }

  .home-inspiring-stories .swiper-button-prev {
    left: 10%;
  }

  .home-inspiring-stories .swiper-button-next {
    right: 10%;
  }

  /* About us */
  .leadership-list li {
    width: 46%;
  }

  .board-image img {
    width: 160px;
  }

  .board-name {
    font-size: 15px;
    margin-top: 20px;
    text-align: center;
  }

  .section-wrapper {
    margin-bottom: 30px;
  }
}

@media (max-width: 500px) {
  .banner-content-box {
    padding-top: 250px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
  }

  /*Header*/
  .common-btn {
    font-size: 16px;
  }

  #header.headerBg {
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  }

  #header .logo,
  #header.logedin-header .logo img {
    width: 200px;
  }

  .banner-cta button.common-btn {
    font-size: 18px;
  }

  .header-search-input input[type="text"] {
    font-size: 22px;
  }

  /*TS Profile Detials*/
  .ts-detial-body,
  .ts-profile-cta {
    padding-left: 15px;
    padding-right: 15px;
  }

  .count-section ul li>div>span {
    display: block;
    font-size: 12px;
    line-height: 18px;
  }

  /*Telescientest page*/
  .ts-list {
    flex-direction: column;
    max-width: 500px;
    margin: 0 auto;
  }

  .ts-list li {
    margin-left: 0;
    max-width: 100%;
    width: 100%;
  }

  .home-tele-scientists-slider .swiper-slide>div {
    flex-direction: column;
  }

  /*Main container right*/
  .dr-container-heading-sec {
    flex-direction: column;
    padding: 0;
  }

  .dr-right-contaier {
    width: 100%;
    margin-top: 10px;
  }

  .dr-container-heading-sec h1 {
    font-size: 20px;
    font-weight: 600;
  }

  /*Home*/
  .home-banner {
    min-height: 91vh;
    background-position: center;
  }

  .banner-content-box h1 {
    font-size: 22px;
    text-align: center;
    padding-left: 0;
  }

  .count-section ul {
    padding: 15px 0;
  }

  .count-section {
    bottom: -40px;
  }

  .home-how-it-work .container {
    background-image: none;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .section-heading {
    font-size: 22px;
    line-height: 1.57;
  }

  .HT-cost {
    padding: 30px 0;
  }

  .ht-cost-body {
    padding: 25px 0 15px;
  }

  .ht-cost-pack {
    padding: 20px;
    width: 100%;
  }

  .section-sub-heading {
    font-size: 16px;
    line-height: 1.57;
    font-weight: 500;
  }

  .single-story-content {
    margin-bottom: 0;
  }

  .inspiring-story-section {
    padding: 20px;
  }

  .why-synaptical {
    padding-bottom: 0;
  }

  .inspiring-story-section h2 {
    font-size: 22px;
    font-weight: 500;
  }

  .inspiring-story-button-wrapper {
    margin: 20px 0;
  }

  .how-it-work-content {
    max-width: 100%;
  }

  .home-how-it-work-cta .common-btn {
    padding: 15px 30px;
  }

  .how-it-work-content ul li .home-how-it-work-icon {
    height: 100px;
    min-width: 100px;
  }

  .how-it-work-content ul {
    margin-top: 20px;
  }

  .how-it-work-content ul li {
    flex-direction: column;
    margin-bottom: 20px;
  }

  .home-how-it-work-props {
    display: none;
  }

  .home-how-it-work-text {
    padding-left: 0;
  }

  .home-how-it-work-cta {
    padding-left: 0;
  }

  .home-our-tele-scientists {
    margin-top: 50px;
  }

  .what-is-telescience {
    padding-top: 30px;
  }

  .what-is-telescience .section-heading {
    font-size: 20px;
    font-weight: 600;
  }

  .what-is-telescience-row.row1 .what-is-telescience-content,
  .what-is-telescience-row.row2 .what-is-telescience-content {
    padding: 20px;
    box-shadow: 0 0 5px rgb(0 0 0 / 15%);
  }

  .what-is-telescience-row.row2 .what-is-telescience-content {
    border-radius: 10px 50px 10px 10px;
  }

  .what-is-telescience-row.row1 {
    padding: 5px;
  }

  .what-is-telescience-row.row1 .what-is-telescience-content {
    border-radius: 50px 10px 10px 10px;
  }

  p.telescience-title {
    margin-bottom: 10px;
  }

  p.telescience-content {
    font-size: 16px;
  }

  .telescience-icon {
    display: none;
  }

  .what-is-telescience-content {
    position: static;
    line-height: 1.48;
    margin-top: 20px;
  }

  .what-is-telescience-row.row1 .what-is-telescience-content {
    margin-top: 20px;
  }

  .home-services {
    display: none;
  }

  .home-services-mobile {
    display: block;
    padding: 30px 0;
  }

  .home-services-card ul li {
    background: url(../../public/assets/images/icons/Check.svg) no-repeat 0 5px;
    padding-left: 30px;
    background-size: 16px auto;
  }

  #home-partnership-slider {
    padding-left: 0;
    padding-right: 0;
  }

  #home-partnership-slider .swiper-pagination {
    display: block;
    bottom: 0;
  }

  #home-partnership-slider .swiper-button-prev,
  #home-partnership-slider .swiper-button-next {
    display: none;
  }

  .home-our-tele-scientists:before {
    top: -30px;
  }

  .hts-left,
  .home-tele-scientists-pic,
  .hts-right {
    max-width: 100%;
  }

  .home-tele-scientists-slider .swiper-slide {
    padding: 0;
  }

  .hts-left {
    margin-bottom: 15px;
    margin-right: 0;
  }

  footer {
    padding: 30px 0 0;
  }

  .footer-menu {
    flex-direction: column;
  }

  .footer-menu li:not(:first-child) {
    margin-top: 20px;
  }

  .static-page h1 {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 10px;
  }

  .pm-features-block {
    flex-direction: column-reverse;
  }

  .paid-membership-price-block {
    margin-left: 0;
  }

  .paid-membership-block h3 {
    font-size: 20px;
  }

  .paid-membership-block h4 {
    font-size: 16px;
  }

  .paid-membership-block h4 {
    margin-top: 10px;
  }

  .paid-membership-price-block {
    max-width: 100%;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }

  .paid-membership-features .common-btn {
    margin: 30px auto 0;
  }

  /* What is telescience */
  .what-is-telescience-row.row2 .telescience-img-box {
    border-radius: 50px 10px 10px 10px;
  }

  .what-is-telescience-row.row1 .telescience-img-box {
    border-radius: 10px 50px 10px 10px;
  }

  /*Our Team*/
  .team-list {
    flex-direction: column;
  }

  .team-list>li {
    width: 100%;
    margin-right: 0;
  }

  .team-list>li:nth-child(3n),
  .team-list>li:nth-child(4n),
  .team-list>li:nth-child(2n) {
    margin-right: 0;
  }

  .team-member-pic img {
    width: 100%;
    height: auto;
    cursor: pointer;
  }

  .team-content h3 {
    font-size: 18px;
    cursor: pointer;
  }

  .team-content p {
    font-size: 16px;
  }

  /*Our Team Details*/
  .team-member-detail-box {
    flex-direction: column;
  }

  .team-member-detail-pic {
    margin: 0 auto;
    width: 200px;
    height: 200px;
    flex: none;
  }

  .team-member-detail-content {
    text-align: center;
    padding-left: 0;
    margin-top: 20px;
  }

  .modal-flex {
    width: 100%;
  }

  /*FAQ*/
  .faq-question h4 {
    font-size: 18px;
  }

  /* Career Page */
  .job-title-wrapper {
    margin-bottom: 18px;
    align-items: start;
    flex-direction: column;
  }

  .job-title {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 15px;
  }

  .job-location {
    padding: 5px 10px;
    font-size: 14px;
  }

  .job-position-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .job-position-text,
  .experience-text,
  .keyword-text {
    font-size: 14px;
    line-height: 20px;
  }

  .job-position-number,
  .experience-number {
    font-size: 14px;
    line-height: 20px;
  }

  .number-of-position {
    padding: 0;
  }

  .number-of-position::before {
    content: "";
  }

  .job-box-wrapper {
    padding: 15px;
  }

  .keyword-text {
    display: none;
  }

  .job-keywords {
    white-space: nowrap;
    font-size: 14px;
  }

  .job-apply-btn {
    justify-content: center;
  }

  /* About us */
  .about-team-content h3 {
    text-align: center;
  }

  .about-us-content-wrapper {
    margin-bottom: 30px;
  }

  .board-card {
    width: 40%;
  }
}

@media (max-width: 280px) {
  .banner-cta button.common-btn {
    width: 239px;
  }

  .feature-description {
    width: 100%;
  }

  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    right: 0 !important;
  }

  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: 0px !important;
  }

  .banner-title.second-title {
    font-size: 35px;
    padding-left: 70px;
  }

  .home-our-tele-scientists:before {
    top: -18px;
  }

  .session-button-wrapper {
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }

  .ht-cost-note {
    margin-right: 0;
  }

  p.slider_bottom_text {
    display: block;
    text-align: center;
  }

  .home-consult-scienctists h4 {
    font-size: 27px;
  }

  .footer-bottom-links {
    align-items: center;
    display: block;
  }

}